//***************************** BUTTON Begin ***********************************

@mixin btn_color($--border-color, $--bg-color, $--color, $--hover  ) {
    border: 1px solid $--border-color!important;
    background-color: $--bg-color!important;
    color: $--color!important;

    &:hover {
        @if type-of($--hover) == 'number' {
            opacity: $--hover!important;
        } @else {
            background-color: rgba($--hover, .5)!important;
        }
        
    }
}


@mixin btn_panel {
    border-radius: $border-radius-xxs;  

    &.btn-dark {
        @include btn_color($-btn-border-color-blue-dark, $-btn-bg-color-blue-dark, $-btn-text-color-blue, 0.7)
    }

    &.btn-light {
        @include btn_color($-btn-border-color-blue, $-btn-bg-color-blue, $-btn-text-color-white, $-btn-bg-color-blue)
    }

    &.btn-clear {
        @include btn_color($-btn-border-color-blue, $-btn-bg-color-white, $-btn-text-color-blue-dark, 0.7)
    }
}

@mixin btn_popup {
    border-radius: $border-radius-xxs;  

    &.btn-light {
        @include btn_color($-btn-border-color-blue, $-btn-bg-color-blue, $-btn-text-color-white, $-btn-bg-color-blue)
    }

    &.btn-clear {
        @include btn_color($-btn-border-color-blue, $-btn-bg-color-white, $-btn-text-color-blue, 0.7)
    }

}

.btn {
    @extend .btn;

    &.btn-panel {
        @include btn_panel;
    }

    &.btn-popup {
        @include btn_popup;
    }
    
    &.btn-border-radius-xxs {
        border-radius: $border-radius-xxs;  
    }

    &.btn-border-radius-xs {
        border-radius: $border-radius-xs;  
    }

    // //set up button used into a panel
    // &.btn-panel, &.btn-popup {
    //     border-radius: $border-radius-xxs;
        
    //     &.btn-dark {
    //         border: 1px solid $-btn-border-color-blue;
    //         background-color: $-btn-bg-color-blue-dark;
    //         color: $-btn-text-color-blue;

    //         &:hover {
    //             // background-color: rgba($-btn-bg-color-blue-dark, .5);
    //             opacity: 0.7;
    //         }
    //     }

    //     &.btn-light {
    //         border: 1px solid $-btn-border-color-blue;
    //         background-color: $-btn-bg-color-blue;
    //         color: $-btn-text-color-white;

    //         &:hover {
    //             background-color: rgba($-btn-bg-color-blue, .5);
    //         }
    //     }

    // }

    // &.btn-popup {
    //     &.btn-clear {
    //         border: 1px solid $-btn-border-color-blue;
    //         background-color: $-btn-bg-color-white;
    //         color: $-btn-text-color-blue;

    //         &:hover {
    //             // background-color: rgba($-btn-bg-color-white, .5);
    //             opacity: 0.7;
    //         }
    //     }
    // }
}


//***************************** BUTTON End *************************************