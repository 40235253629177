//This file is all about the popups

@import '_button.scss';

.modal.in .modal-dialog {
    -webkit-transform: translate(0,10%);
    -ms-transform: translate(0,10%);
    -o-transform: translate(0,10%);
    transform: translate(0,10%);
}

.modal-content {
    // color: white;
    background-color: $-popup-bg-color;
}

.modal-lg { 
    width: 60%!important; 
    max-width: none;
    min-width: auto;
}

.modal-xlg { 
    width: 80%!important; 
    max-width: none;
    min-width: auto;
}

.modal-body {
    max-height: calc(100vh - 220px);

    .max-height-modal-body {
        max-height: calc(100vh - 250px);
    }
}

.modal-header {
    background-color: $-popup-header-bg-color;
    color: $-popup-header-text-color;
    height: 40px;
    padding: 1rem;

    .title {
        width: calc(100% - 30px);
        text-align: center;
    }

    .cancel {
        width: 30px;
        text-align: right;

        img {
            width: 20px;
            cursor: pointer;
        }
    }
}

.modal-footer {
    height: 50px;
    border-top: 0;
    background-color: #f3f3f3;
}

.modal-title {
    color: $-popup-text-color-white;
    font-size: 15px;
    font-weight: bold;
    font-family: $-font-family;

}

md-dialog-actions {
    text-align: center!important;
    display: inline!important;
}

.md-scroll-mask {
    background-color: rgba(128, 128, 128, 0.383);
    z-index: 1999;
}

.md-dialog-container {
    z-index: 2000;
}



//button used into confirm-dialog
@mixin md_button {
    border-radius: $border-radius-xxs;  

    min-width: 0;
    min-height: 0;
    border-radius: 3px;
    padding: 5px 10px;
    font-size: 12px;
    font-family: "Roboto";
    text-transform: none;

    &.md-button {
        line-height: normal;
    }
}

//set up the confirm-dialog's cancel button 
.md-cancel-button {
    @include btn_color($-btn-border-color-blue, $-btn-bg-color-white, $-btn-text-color-blue, 0.7);
    @include md_button();
}    

//set up the confirm-dialog's confirm button 
.md-confirm-button {
    @include btn_color($-btn-border-color-blue, $-btn-bg-color-blue, $-btn-text-color-white, $-btn-bg-color-blue);
    @include md_button();
}    

//set up max-height equal to the parent max-height 
//for all the div descendent
.max-height-inherent div {
    max-height: inherit;
}

