@import '../_variables.scss';
@import '../_theme.scss';
@import '../inc/bootstrap/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss';

.owner-notifications {

  .content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.6;
  }
}
