@import '../_variables.scss';
@import '../_theme.scss';
@import '../inc/bootstrap/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss';

.asset-map-widget {
  overflow: visible;

  .asset-map-container {
    display: block;
    width: 100%;
  }

  .status-legend-items {
    width: 240px;
    background-color: $-page-bg-color;
    color: #fff;
    line-height: 20px;
  }
}
