// Opacity

@mixin opacity($opacity, $is_imp: false) {
  @if $is_imp == false {
	  opacity: $opacity;
	  // IE8 filter
	  $opacity-ie: ($opacity * 100);
	  filter: alpha(opacity=$opacity-ie);
	} @else {
	  opacity: $opacity !important;
	  // IE8 filter
	  $opacity-ie: ($opacity * 100)  !important;
	  filter: alpha(opacity=$opacity-ie)  !important;
	}
}
