//Write here all the classes about the application domain

@import '_variables.scss';
@import 'inc/bootstrap/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss';
@import '_mixins.scss';


@mixin data_show($font-size, $label-color, $label-bold, $value-color) {
    font-size: $font-size;

    .label {
        all: unset; //exclude all the .label styles defined into another file
        color: $label-color;
        font-weight: $label-bold;
    }

    .value {
        color: $value-color;
    }
}

.company-logo {
    visibility: hidden;
    
    &.img {
        max-height: 50px;
        max-width: 170px;
        margin: 2px 30px 2px 40px;
    }

    &.text {
        font-size:  26px;
        margin: 5px;
    }
}

//========================================== USER-MENU begin =========================================================

ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
}

#user-block ul li.account .name {
    font-size: 12px;
    font-family: Roboto; }
    @media only screen and (min-width: 1024px) {
      #user-block ul li.account .name {
        fonnt-size: 12px; } }
    @media only screen and (min-width: 1600px) {
      #user-block ul li.account .name {
        font-size: 12px; } }
  
  .page-header-company-name-text {
    display: none; }
    @media only screen and (min-width: 1024px) {
      .page-header-company-name-text {
        display: inline; } }
  
  .page-header-home-icon.fa {
    font-size: 22px;
    display: inline; }
    @media only screen and (min-width: 1024px) {
      .page-header-home-icon.fa {
        display: none; } }
  
  #user-block .info {
    display: none; }
    @media only screen and (min-width: 1600px) {
      #user-block .info {
        display: block; } }
  
  .page-header #user-block ul li.account {
    min-width: auto;
    padding-right: 0;
    // margin-right: 20px; 
}
    @media only screen and (min-width: 1600px) {
      .page-header #user-block ul li.account {
        min-width: 245px;
        // margin-right: 55px;
        padding-right: 20px; 
    } 
}
  
  .page-header #user-links {
    width: 300px; }
    @media only screen and (min-width: 1600px) {
      .page-header #user-links {
        width: auto; } }

/* user block */
#user-block {
    float: right;
    height: 60px;
    position: relative;
}

#user-block .page-company-name {
    display: none;
}
#user-block ul{
}
#user-block ul li,
#user-block ul a {
    float: left;
}
#user-block ul{
    width: 100%;
}
#user-block ul li.separator span{
    display: inline-block;
    height: 27px;
    width: 1px;
    background: #54647a;
    margin: 0 20px;
}
#user-block ul li.account{
    min-width: 85px;
    font-size: 12px;
    line-height: 15px;
    position: relative;
    cursor: pointer;
    padding-right: 20px;
    margin-right: 55px;
    height: 60px;
    border-left: 1px solid #e1e6e8;
    padding-top: 12px;
    box-sizing: border-box;
    font-size: 12px;
}

.page-header #user-block ul li.account {
    border-left: none;
}

#user-block ul li.account:hover, #user-block ul li.account.open{
    color: #008fd1;
}

#user-block ul li.account .avatar{
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-left: 20px;
    float: left;
    border: 1px solid #c1cbd2;
}

#user-block ul li.account .info {
    padding-left: 66px;
    padding-top: 5px;
}

.page-header #user-block ul li.account .name {
    color: #fff;
     text-transform: capitalize;
}

#user-block ul li.account .log-in-on-behalf{
    width: 120px;
    color: #96a7b1;
    font-family: Roboto;
    margin-top: -13px;
    margin-bottom: 3px;
}

#user-block ul li.account .name:before{
    content: "";
    position: absolute;
    width: 9px;
    height: 6px;
    background-image: url(/img/icons/menu_arrows.png);
    background-position: 0 0;
    right: 0px;
    top: 22px;
}

#user-block ul li.account .role{
    color: #96a7b1;
    font-family: Roboto;
    font-style: italic;
}

.page-header #user-block ul li.account .role {
    color: #fff;
     text-transform: capitalize;
}

#user-block ul li.account:hover .name:before{
    border-color: #90accd transparent transparent transparent;
}
#user-block ul li.account.open .name:before{
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #90accd transparent;
}


/* user links in header */
#user-links{
    position: absolute;
    right: 0;
    top: 60px;
    z-index: 99999;
    background: #fff;
    text-align: left;
    min-width: 100%;
    width: auto;
    max-height: 0;
    overflow: hidden;

    -webkit-border-bottom-right-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;

    -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.page-header #user-links {
    background: rgb(67, 89, 109);

    &.customer {
        background: white;
    }
}



.open #user-links {
    overflow: visible;
    max-height: 500px;
}

#user-links:before{
    content: "";
    height: 10px;
    top: -10px;
    border-bottom: 1px solid #E1E1E1;
    position: absolute;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
    width: 100%;
}
#user-links ul li{
    display: block;
    padding: 3px 0 3px 20px;
    width: 100%;
}

#user-links ul li.menu-divider {
    margin: 10px 0;
    border: 1px solid #ccc;
    height: 0;
    padding: 0;
}

#user-links .nested-menu {
    font-family: Roboto;
    font-size: 14px;
    color: white;
}

.page-header .menu-divider {
    border-top-color: #fff;
}

#user-links ul li:first-child{
    margin-top: 10px;
}
#user-links ul li:last-child{
    margin-bottom: 10px;
}

#user-links ul li a{
    white-space: nowrap;
    color: #272b30;
    font-family: Roboto;
    font-size: 14px;
    line-height: 22px;
}

.page-header #user-links ul li a {
    color: #fff;
}

.page-header #user-links.customer ul li a {
    color: #000;
}


#user-links ul li a:hover, #user-links.customer ul li a:hover{
    color: #008fd1;
    text-decoration: underline;
}

.nested-menu-container .nested-menu-children {
    display: none;
    position: absolute;
    margin-top: 10px;

    background: rgb(67, 89, 109);
    box-shadow: -1px -1px 5px white;
}

.nested-menu-container.open .nested-menu-children {
    display: block;
}
//========================================== USER-MENU end ===========================================================


//========================================== Dashboard begin =========================================================
@import "dashboard/dashboard";
//========================================== Dashboard end ===========================================================


//========================================== CUSTOMER begin ==========================================================
    //set up the customer dashboard style
    .customer-dashboard {
        min-height: $customer-dashboard-height;
        max-height: $customer-dashboard-height;

        .title {
            font-size: 20px;
        }

        //set up the element to show some information
        .data-show {
            @include data_show(10px, white, normal, rgb(0, 147, 248));
            // font-size: 10px;

            // .label {
            //     all: unset; //exclude all the .label styles defined into another file
            //     color: white;
            // }

            // .value {
            //     color: rgb(0, 147, 238);;
            // }
        }
    }

    //used if a column contains two rows, one row is the customer-dashboard
    //remove from the height, the asset-dashboard height
    .h-100 .with-customer-dashboard {
        height: calc(100% - #{$customer-dashboard-height})!important;
    }

    // //set up the switch about the customer status
    // .custom-switch {
    //     md-switch {
    //         margin: 0;
    //     }
    //     md-switch.md-checked:not([disabled]) {
    //         .md-bar {
    //             background-color: $-status-bar-bg-color;
    //         }
    //         .md-thumb {
    //             background-color: white;
    //             left: 0px;
    //         }
    //         .md-label {
    //             color: $-status-color;
    //         }
    //     }
    // }

    .customer-list-status {
        margin-right: 5px;
        width: 15px;
        height: 15px;
        color: white;
        font-weight: bold;
        text-align: center;
        line-height: 20px;
        border-radius: $border-radius-sm;
        background: #7e8b8c;

        &.smaller {
            width: 10px;
            height: 10px;
            margin-top: 3px;
        }

        &.inactive {
            background: #7e8b8c;
        }
    
        &.active {
            background: #58af30;
        }
    
    }

    //set up the customer's logo
    .customer-logo {
        width: $customer-logo-width;

        &.with-border {
            border: 1px dashed $customer-logo-border-color;
        }
    }
    .customer-logo-width {
        width: $customer-logo-width + 20px;
    }

    .with-customer-logo-width {
        width: calc(100% - (#{$customer-logo-width} + 20px))
    }

    //set up the customer-list panel
    .customer-list-width {
        max-width: $customer-list-width;
        min-width: $customer-list-width;
    }

    //set up the panel beside the customer-list one
    .with-customer-list-width {
        width: calc(100% - #{$customer-list-width});
    }

    //set up the customer-info panel
    .customer-info-width {
        max-width: $customer-info-width;
        min-width: $customer-info-width;
    }

    //set up the panel beside the customer-info one
    .with-customer-info-width {
        width: calc(100% - #{$customer-info-width});
    }

    //----------------------------Customer popup: begin---------------------------------------
        //set up the left column into the customer popup
        .customer-popup-sx {
            width: $customer-popup-sx-width;
        }

        //set up the right column into the customer popup
        .customer-popup-dx {
            width: calc(100% - #{$customer-popup-sx-width});
        }

        //set up the left and right columns into the customer popup
        .customer-popup-sx, .customer-popup-dx {

            .shadow {
                box-shadow: 0px 3px 6px $-shadow-color;
            }
        }

        .customer-popup-section {
            color: black;
            :hover {
                @extend .my-hover;
                cursor: pointer;
                background-color: rgba($-hover-bg-color, .2)!important;
                color:black!important;

            }

            &.selected {
                cursor: pointer;
                background-color: rgba($-hover-bg-color, .2)!important;
                color:black!important;
            }
        }
    //----------------------------Customer popup: end-----------------------------------------
//========================================== CUSTOMER end ============================================================

//========================================== ASSET begin ==========================================================
    

    .asset-estate-popup {
        height: 600px;
    }

    .asset-status {
        margin-right: 5px;
        width: 15px;
        height: 15px;
        color: white;
        font-weight: bold;
        text-align: center;
        line-height: 20px;
        border-radius: $border-radius-sm;
        background: #7e8b8c;

        &.smaller {
            width: 10px;
            height: 10px;
            margin-top: 3px;
        }

    }

    .asset-status-wrapper {
        border-radius: $border-radius-xs;
        padding: 1px 5px;

        &.border {
            border: 1px solid #00569a;
        }
    }

    .asset-status.unknown {
        background: #7e8b8c;
    }

    .asset-status.good_condition {
        background: #58af30;
    }
    

    .asset-status.low_schedule {
        background: #ffd600;
    }

    .asset-status.maintenance_schedule {
        background: #f39619;
    }

    .asset-status.urgent_attention {
        background:#d51026;
    }

    .asset-status.na {
        background: gray;
        margin-top: 10px;
    }
    
    .asset-status.delivered {
        background: green;
        margin-top: 10px;
    }
    
    .asset-status.pending {
        background: #F7B00A;
        margin-top: 10px;
    }
    
    .asset-status.notdelivered {
        background: red;
        margin-top: 10px;
    }

    .asset-status.completed {
        background: green;
        margin-top: 10px;
    }
    
    .asset-status.inprogress {
        background: #F7B00A;
        margin-top: 10px;
    }
    
    .asset-status.incomplete {
        background: red;
        margin-top: 10px;
    }


    .asset-status-project {
        margin-right: 5px;
        width: 15px;
        height: 15px;
        color: white;
        font-weight: bold;
        text-align: center;
        line-height: 20px;
        border-radius: $border-radius-sm;
        background: #7e8b8c;

        &.smaller {
            width: 10px;
            height: 10px;
            margin-top: 3px;
        }

    }

    .asset-status-project-wrapper {
        border-radius: $border-radius-xs;
        padding: 1px 5px;

        &.border {
            border: 1px solid #00569a;
        }
    }

    .asset-status-project.unknown {
        background: #7e8b8c;
    }

    .asset-status-project.not_started {
        background: #ffd600;
    }

    .asset-status-project.work_in_progress {
        background: #f39619;
    }

    .asset-status-project.pending {
        background:#d51026;
    }

    .asset-status-project.completed {
        background: #58af30;
    }

    .asset-icon {
        width: 20px;
        height: 20px;
    }


    //----------------------------Customer status chart: begin-----------------------------------
        .customer-status {
            margin-right: 5px;
            width: 15px;
            height: 15px;
            color: white;
            font-weight: bold;
            text-align: center;
            line-height: 20px;
            border-radius: $border-radius-sm;

            &.smaller {
                width: 10px;
                height: 10px;
                margin-top: 3px;
            }

        }

        .customer-status-chart.active {
            background-color: #0093ee;
        }

        .customer-status-chart.inactive {
            background-color: #212f41;
        }

        .customer-status.active {
            background-color: #0093ee;
        }

        .customer-status.inactive {
            background-color: #212f41;
        }

    //----------------------------Customer status chart: end-------------------------------------
    


    //----------------------------Asset status chart: begin-----------------------------------


        @media(min-width:1046px) {
            .asset-status-chart-width {
                width: 194px!important;
            }
            .with-asset-status-chart-width {
                width: calc(100% - 194px)!important;
            }
        }

        //set up the asset status into the chart
        // .asset-status-chart.unknown {
        //     background-color: gray;
        // }

        // .asset-status-chart.good_condition {
        //     background-color: green;
        // }

        // .asset-status-chart.maintenance_schedule {
        //     background-color: #F7B00A;
        // }

        // .asset-status-chart.urgent_attention {
        //     background-color: red;
        // }

        .asset-status-chart.unknown {
            background: #7e8b8c;
        }
    
        .asset-status-chart.good_condition {
            background: #58af30;
        }
        
    
        .asset-status-chart.low_schedule {
            background: #ffd600;
        }
    
        .asset-status-chart.maintenance_schedule {
            background: #f39619;
        }
    
        .asset-status-chart.urgent_attention {
            background:#d51026;
        }
    
    //----------------------------Asset status chart: end-------------------------------------

    //set up google map used for assets/estates
    .google-map {
        &.default {
            height: 100%;
            width: 100%;
        }

        //set up the size about google map used to show the assets/estates
        &.asset {
            height: 100%;
            width: 100%;
        }

        &.asset-popup, &.estate-popup {
            height: 400px;
            width: 100%;
        }

        //set up the button position on the map to go back to assets/estates list
        &.asset-list-btn {
            position: absolute;
            top: 10px;
            right: 10px;
        }

        &.spin-model-popup {
            height: 100%;
            width: 100%;
        }


    }

    .asset-mapper {
        height: 100%;
        width: 100%;
    }

    .matterport {
        height: 100%;
        width: 100%;
    }

    //----------------------------Asset 3D Model: begin-----------------------------------
        .model3d-own-by-sky-futures {
            color: red;
        }

        .model3d-own-by-company {
            color: black;
        }

        .photogrammetry-model {
            color: green;
        }

        .model3d-table-popup {
            // height: 560px!important;
            background: $-input-bg-color;
        }

        .model3d-data-show {
            @include data_show($main-font-size, black, bold, black);
        }
    //----------------------------Asset 3D Model: end-------------------------------------


//========================================== ASSET end ============================================================

//========================================== ASSET-DATA begin =====================================================
    .thermal-imagery-slider {
        display: inline-flex;
        width: 100%;
        
        .slider {
            width: 100%;
        }

        .text {
            padding-top: 16px;
        }
    }

    .asset-data-popup {

        .panel-container {
            height: 650px;
        }

        .panel-container-top {
            height: 75px;
            background-color: #212f41;

        }

        .shadow {
            box-shadow: 0px 3px 6px $-shadow-color;
        }

        .numberCircle {
            font-size: 14;
            width: 30px;
            height: 30px;
          
            background-color: #0692eb;
            padding: 1rem;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            color: white; 
        }

        .numberCircle-unselected {
        
            background:rgba(255,255,255,0);
            border: 2px solid#0692eb;
        
            font-size: 14;
            width: 30px;
            height: 30px;
          
            padding: 1rem;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            color: #0692eb;; 
        }

        .numberCircle-not-active {
        
            background:rgba(255,255,255,0);
            border: 2px solid gray;
        
            font-size: 14;
            width: 30px;
            height: 30px;
          
            padding: 1rem;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            color: gray;; 
        }

        .nav-bar {
            width:100%;
            border-bottom: 2px solid gray;
          }

          .nav-bar-selected {
            width:100%;
            border-bottom: 2px solid #0692eb;
          }

          .close-icon
            {
            width:20px;
            height:20px;
            background: -webkit-linear-gradient(-45deg, transparent 0%, transparent 46%, white 46%,  white 56%,transparent 56%, transparent 100%), -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, white 46%,  white 56%,transparent 56%, transparent 100%);
            background-color:rgba(255,255,255,0);
            float: right;
            border-radius: 10px;            
            }
        

    }
    .w3{
        .red-panel{
            margin-top:16px;
            margin-bottom:16px;
            margin-left: 15px;
            margin-right: 40%;
            color:#fff!important;
            background-color:#fabdb9!important
        }

        .red-text {
            color: darkred;
            padding-left: 10px;
        }
        
    }
    


    //set up the asset-data-list panel
    .asset-data-list-width {
        // max-width: $asset-data-list-width;
        // min-width: $asset-data-list-width;
        width: 50%;
        position: absolute;
        top: 50px;
        left: 0px;
        height: calc(100% - 50px)!important;
        z-index: 101;
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);        

        &.mobile {
            width: 100%;
            top: 0px;
            height: 100%!important;
        }
    }

    //set up the panel beside the asset-data-list one
    .with-asset-data-list-width {
        width: calc(100% - #{$asset-data-list-width});
    }

    .asset-data-tools-menu {
        img {
            width: 20px;
        }

        div {                
            position: relative;
            height: 24px;
            margin-bottom: 5px;
    
            img {
                margin: auto 0px;
            }
        
            cursor: pointer;
            label {
                margin: auto 0px;
                cursor: pointer;
            }
        }

        .not-enabled {
            opacity: 0.5;
            cursor: default;
            label {
                margin: auto 0px;
                cursor: default;
            }
        }
    }

    .asset-data-tools-width {
        &.col-1 {
            max-width: $asset-data-tools-width-col1;
            min-width: $asset-data-tools-width-col1;
        }
        &.col-2 {
            max-width: $asset-data-tools-width-col2;
            min-width: $asset-data-tools-width-col2;
        }
    }

    .with-asset-data-tools-width {
        width: calc(100% - (#{$asset-data-tools-width-col1} + #{$asset-data-tools-width-col2}));
    }

    .asset-data-tools-height {
        min-height: $asset-data-tools-height;
        max-height: $asset-data-tools-height;
    }

    //used if a column contains two rows, one row is the asset-data-tools
    //remove from the height, the asset-data-tools height
    .h-100 .with-asset-data-tools-height {
        // height: calc(100% - #{$asset-data-tools-height})!important;
        height: 100%!important;
    }

    .asset-data-list-panel-tag  {
        position: absolute;
        left: 0px;
        width: 40px;
        height: 35px;
        background: #161b2ee0;
        z-index: 102;
        margin-top: -10px;
        // border-radius: 0 5px 5px 0;
     }
    .asset-data-list-panel {
        background-color: #2c405a;
        color: $side-panel-text-color;
        font-size: $side-panel-font-size;
        height: 100%;
        
        .myInput.pageNo {
            width: 60px!important;
        }

        .icon {
            width: 20px;
        }

    }

    .asset-data-list {
        .not-available {
            color: gray;
            font-style: italic;
        }
        .error {
            color: red;
            font-style: italic;
        }
        .on-going {
            color: #00b9e6;
            font-style: italic;
        }
    }

    .image360 {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        // left: 50%;
        // -ms-transform: translateX(-50%);
        // -moz-transform: translateX(-50%);
        // -webkit-transform: translateX(-50%);
        // transform: translateX(-50%);        
    }

    .image-container {
        height: inherit;
        text-align: center;

        img {
            max-height: 100%;
            max-width: 100%;
        }
    }


    .images-videos-column-width {
        max-width: $images-videos-column-width;
        min-width: $images-videos-column-width;
    }

    .with-images-videos-column-width {
        width: calc(100% - #{$images-videos-column-width*2});
    }

    .images-videos-tools {
        min-height: $images-videos-tools-height;
        max-height: $images-videos-tools-height;
        text-align: center;


        .btn-group {
            display: inline-flex;
            margin: 0 auto;
        }

        .btn-tool {
           width: 40px;
           height: 40px;

           img {
                width: 24px;
            }

            &.selected {
                border: 2px solid #0093ee!important;
            }

        }

    }

    .images-videos-thumbs {
        min-height: $images-videos-thumbs-height;
        max-height: $images-videos-thumbs-height;

        .img-container {
            display: inline;
        }


        // img {
        //     max-height: 60px;
        //     max-width: 100px;

        // }
    }

    .images-videos-thumbs-ocr {
        // min-height: 300px;
        max-height: 300px;

        .ocr-text {
            max-height: 50px;
        }
    }


    .h-100 .with-images-videos-tools-and-thumbs {
        height: calc(100% - #{$images-videos-tools-height + $images-videos-thumbs-height})!important;
        text-align: center;

        img {
            max-height: 100%;
            max-width: 100%;
        }
    }

    .h-100 .with-images-videos-tools {
        height: calc(100% - #{$images-videos-tools-height})!important;
        text-align: center;

        .img-container {
            display: inline;
        }
        // img {
        //     max-height: 60px;
        //     max-width: 100px;
        // }
    }

    .h-100 .with-images-videos-thumbs {
        height: calc(100% - #{$images-videos-thumbs-height})!important;
        text-align: center;

        img {
            max-height: 100%;
            max-width: 100%;
        }
    }

    .h-100 .with-images-videos-number {
        height: calc(100% - 20px)!important;
        text-align: center;

        img {
            max-height: 100%;
            max-width: 100%;
        }
    }

    .h-100 .with-images-360-thumbs {
        height: calc(100% - #{$images-videos-thumbs-height})!important;
        text-align: center;

        img {
            max-height: 100%;
            max-width: 100%;
        }
    }


    .thumb-multi-container {
        width: 130px;
        height: 107px;
        background-color: #212f41;

        .video-icon {
            &:after {            
                content:'';
                background: url(/img/icons/asset_data_type/video.png) no-repeat;
                width: 20px;
                height: 20px;
                background-size: 20px;
                margin: auto;
                // // transform: translateX(-10px) translateY(-40px)!important;        
                // transform: translateX(-55px) translateY(15px)!important;
                // -ms-transform: translateX(-55px) translateY(15px)!important;
                // -moz-transform: translateX(-55px) translateY(15px) !important;
            }    
        }
    }


    .thumb-container {
        width: 110px;
        height: 62px;
        background-color: #2c405a;    

        .video-icon {
            &:after {            
                content:'';
                background: url(/img/icons/asset_data_type/video.png) no-repeat;
                width: 20px;
                height: 20px;
                position: absolute;
                background-size: 20px;
                transform: translateX(-55px) translateY(15px);
                -ms-transform: translateX(-55px) translateY(15px);
                -moz-transform: translateX(-55px) translateY(15px);
            }    
        }
        .audio-icon {
            &:after {            
                content:'';
                background: url(/img/icons/asset_data_type/audio_thumb.png) no-repeat;
                width: 91px;
                height: 52px;
                position: absolute;
                background-size: 92px;
                transform: translateX(-91px) translateY(1px);
                -ms-transform: translateX(-91px) translateY(1px);
                -moz-transform: translateX(-91px) translateY(1px);
            }    
        }

    }


    .thumb {
        max-height: 56px;
        max-width: 100px;
        margin: auto;

        &.video {
            max-height: calc(100% - 5px);
            max-width: calc(100% - 5px);
        }

        &.audio {
            max-height: calc(100% - 5px);
            max-width: calc(100% - 5px);
        }

        &.selected {
            border: 2px solid #0093ee;
            // opacity: .5;
        }

        &.nokia-report {
            max-height: 200px;
            max-width: 300px;
            height: inherit;
            width: inherit;
        }
    }

    .video-container {
        width: 100%;
        height: 100%;
    }

    .photogrammetry-frame {
        width: 100%;
        height: 100%;
        background-color: rgb(61, 61, 61);
    }
    
    .inspection-report {
        
        .detail-img-height {
            height: 500px;
        }
        .question-col1 {
            width: 20px;
        }
        .question-col2 {
            width: calc(100% - 20px);
        }

        .reach-text {
            max-height: 8em!important;
            min-height: 8em;
            border-radius: 5px;
        }       
        
        .question-group {
            height: 40px;
            background: #144368;       
            padding-left: 30px;     
        }

        .question-columns {
            background-color: #364b67;
        }
        
        .question {
            padding: 5px 0;
            border-bottom: 1px solid #34546d;            
        }

        .details {
            background: #26364a;            
        }

        .result {
            width: 100px;            
        }

        .status {
            width: 20px;
            height: 20px;
            text-align: center;
            border-radius: 5px;            
            border: 1px solid rgb(82, 82, 82);
            background: rgb(41, 41, 41);

            &.green {
                border: 1px solid #04e204;
                background: green;
            }
            &.red {
                border: 1px solid rgb(255, 95, 95);
                background: rgb(255, 0, 0);
            }
            &.pending &.gray{
                border: 1px solid rgb(137, 137, 137);
                background: rgb(37, 37, 37);
            }

        }
    }
//========================================== ASSET-DATA end =======================================================


//========================================== COMPASS begin =======================================================
//Compass into "2D Compass Viewer" screen
.compass-options-container {
    position: absolute;
    top: 120px;
    left: 60px;
    z-index: 5;

    .options {
        background-color: #212f41;
        border: 1px solid #0093ee;
        width: calc(100% + 40px);
        height: 100%;
        padding: 10px;
    }
}

.compass-container {
    display: block;
    width: $compass-size;
    height: $compass-size;
    position: absolute;
    top: 20px;
    left: 60px;
    z-index: 5;
    
    .compass {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: radial-gradient(#5e6875, #435061);
        //background-image: url('/assets/img/compass.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        .compass-image {
            display: block;
            background-image: url('/img/icons/compass/comp-img.png');
            //background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            position: absolute;
            background-size: 80%;
            width: $compass-size;
            height: $compass-size;
        }
        .label-container {
            display: block;
            position: absolute;
            list-style: none;
            padding: 0;
            overflow: hidden;
            width: $compass-size;
            height: $compass-size;
            margin: 0;
            li {
                text-align: center;
                color: yellow;
                font-weight: bold;
                z-index: 99;
                font: 14px Monaco, MonoSpace;
                height: 90px;
                position: absolute;
                width: 15px;
                left: 37px;
                transform-origin: center center;
                padding-top: 5px;
                //display: none;
                pointer-events: none;
                text-shadow: #333d49 -1px -1px 1px;
            }
            li:first-child {
                color: #ff5e75;
                font-size: 14px;
            }
            li:nth-child(2n) {
                font-size: 10px;
                padding-top: 14px; //20px;
                height: 90px; //180px;
                color: white;
            }
            @include rotated-text(8,
            360,
            -45deg);
        }
        .sectors-container {
            position: relative;
            padding: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            overflow: hidden;
            opacity: 1;
            list-style: none;
            li {
                overflow: hidden;
                position: absolute;
                top: 0;
                right: 0;
                width: 50%;
                height: 50%;
                transform-origin: 0 100%;
                cursor: pointer;
                box-sizing: border-box;
                box-shadow: 22px 9px 6px -8px #333d49;
                border: 1px solid #5f6f84;
            }
            $i: -15;
            @for $i from 1 through 8 {
                $deg: ($i - 1) * 45 - 22.3;
                li:nth-child(#{$i}) {
                    transform: rotate(#{$deg}deg) skewY(-44.7deg);
                }
            }
            li.selected,
            li:hover {
                background: linear-gradient(rgba(0, 0, 0, .380392), rgba(121, 206, 194, 0.5)); //linear-gradient(rgba(0, 0, 0, 0.3803921568627451),rgba(75, 84, 95, 0.4));
                //background: #6670a2;
                .text {
                    //display: block;
                }
            }
            li.selected:hover {
                background: radial-gradient(rgba(94, 104, 117, 0.5), rgba(67, 80, 97, 0.5));
            }
        }
    }
}
//========================================== COMPASS end =========================================================

//========================================== SPIN MODEL begin =======================================================
.spin-model {
    .main-panel {
        width: calc(100% - 350px);

        &.with-compass {
            width: 50%;
        }

        &.inventory {
            width: 100%;
        }

        .images {
            height: calc(100% - 60px);
        }
        .buttons {
            min-height: 60px;
            max-height: 60px;            
            text-align: center;
            padding-top: 10px!important;

            img:hover {
                background: #3a506d;
            }

            img {
                padding: 5px;

                &.selected {
                    background: #3a506d;
                }
            }
        }
    }

    .info-panel {
        min-width: 350px;
        max-width: 350px;
        padding-left: 10px;
    }

    .compass-screen {
        &.header {
            height: 60px;
        }
        &.map {
            height: calc(100% - 120px);
        }

        &.footer {
            height: 60px;
        }
    }
    
    .asset-2d {
        text-align: center;

        .level {
            position: absolute;
            border: 1px solid #3a506d3b;
            width: 100%;
            text-align: left;
    
            &.top {
                top: 0;
                left: 0;
                height: 20%;    
            }
            &.middle {
                left: 0;
            }
            &.bottom {
                bottom: 0;
                left: 0;
                height: 20%;    
            }    

            &.selected {
                background: #3a506d59;
            }
        }
        .level:hover {
            background: #3a506d59;
            cursor: pointer;
        }
    }
}
//========================================== SPIN MODEL end =========================================================

//========================================== INVENTORY begin========================================================
.extraInfo {
    &.new_custom_field {
        background-color: #161b2e;
    }
}

.__markerjs2_toolbar_button {
    display: inline-flex!important;
}
.__markerjs2_ {
    width: 50%!important;
    height: 50%!important;
}

//========================================== INVENTORY end =========================================================

#forgeViewer {
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: #F0F8FF;
}

#_360_image_image360 {
    width: 100%;
    height: 100%;
    margin: 0;

}

//========================================== COMPASS BEARING begin =======================================================
.compass-bearing {
    position: absolute; 
    top:10px; 
    left:10px; 
    width:100px; 
    height:100px;
    z-index: 5;
    background: rgba(44, 64, 90, 0.50);
    border-radius: 20px 20px 0 0;

    .compass {
        position: absolute; 
        top:0px; 
        left:0px; 
        width:100px; 
        height:100px; 
        z-index:1;
        content:url("/img/icons/compass.png");
    }
    .compass-needle {
        position: absolute; 
        top:0px; 
        left:0px; 
        width:100px; 
        height:100px; 
        z-index:2;
        content:url("/img/icons/compass_needle.png");
    }
    .compass-text {
        position: absolute;
        top: 100px;
        background: rgba(44, 64, 90, 0.50);
        border-radius: 0 0 20px 20px;
        color: white;
        width: 100px;
        text-align: center;
        left: 0px;
    }
}
//========================================== COMPASS BEARING end =========================================================


.customer-list-container, .customer-list-mobile-container {
    // height: 100%;
    position: absolute; 
    // top: 10px; 
    // left: 10px; 
    height: 50px; 
    width: 350px; 
    background: #161b2ee0; 
    display:inline-flex; 
    z-index: 2;


    .customer-list {
        background: #161b2e;
        // display: inline-flex;
        width: 350px;
        // height: 464.5px;
        position: absolute;
        top: 50px;

        li {
            
            &:hover {
                @extend .my-hover;

                font-weight: bold;
                cursor: pointer;
            }
        } 

    }
}

.customer-list-container{
    top: 10px; 
    left: 10px; 
} 
.customer-list-mobile-container {
    top: 0px; 
    left: 0px; 
}


.asset-menu-container, .asset-menu-mobile-container {
    position: absolute; 
    display: inline-flex;
    // top: 70px; 
    // left: 10px;     
    z-index: 1;
    transition: width 0.5s, height 0.5s;

    
    &.closed {
        height: 65%;
        width: 40px;
    }

    .asset-menu-options {
        background: #161b2ee0;
        transition: width 0.5s, height 0.5s;
        width: 40px;

        &.opened {
            height: 100%;
        }

        &.closed {
            height: 40px;
        }

        .option {
            &.selected {
                background: #0093ee;
            }
    
            &:hover {
                background: #0093ee;
            }    
        }

    }

    .asset-menu-body {
        background: #161b2e;
        transition: width 0.5s, height 0.5s;

        &.opened {
            width: 100%;
            height: 100%;
        }

        &.closed {
            width: 0px;
            height: 0px;
        }
    }
}

.asset-menu-container {
    top: 70px; 
    left: 10px;     

    &.opened {
        width: 55%; 
        height: 60%;
    }
}

.asset-menu-mobile-container {
    top: 60px; 
    left: 0px;     

    &.opened {
        width: 100%; 
        height: 100%;
    }
}

//set up the asset-data-list panel
.dynamic-cad-property-container {
    width: 20%;
    position: absolute;
    top: 39px;
    left: 0px;
    height: calc(100% - 84px)!important;
    z-index: 99;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);      
    
    &.asset-data-split-width {
        width: 40%;
    }

    .dynamic-cad-panel {
        background-color: #2c405a;
        color: $side-panel-text-color;
        font-size: $side-panel-font-size;
        height: 100%;
    }
}

.dynamic-cad-panel-tag  {
    position: absolute;
    left: 0px;
    width: 40px;
    height: 35px;
    background: #161b2ee0;
    z-index: 100;
    margin-top: -10px;
    // border-radius: 0 5px 5px 0;
 }

 .dynamic-cad-measurement-label {
    color: 'white';
    background: #00000060;
    padding: 0 5px;
    border-radius: 5px;
 }

 .extractInfoFromBarcodeAndQrcode {
     font-weight: normal;
 }


 .project-status-wrapper {
    border-radius: $border-radius-xs;
    padding: 1px 5px;

    &.border {
        border: 1px solid #00569a;
    }
}

 .project-status {
    margin-right: 5px;
    width: 15px;
    height: 15px;
    color: white;
    font-weight: bold;
    text-align: center;
    line-height: 20px;
    border-radius: $border-radius-sm;
    background: #7e8b8c;

    &.smaller {
        width: 10px;
        height: 10px;
        margin-top: 3px;
    }

}
.project-status.completed {
    background: #58af30;
}

.project-status.commenced {
    background: #ffd600;
}

.project-status.pending {
    background:#d51026;
}
