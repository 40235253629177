@import '../_variables.scss';
@import '../_theme.scss';
@import '../inc/bootstrap/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss';

$status-green: #21ff34;
$status-blue: #ffd100;

.dashboard-calendar {

  .calendar-container {
    border-bottom: 1px solid $-panel-border-color;
  }

  .calendar-legend {
    //display: inline-block;
    display: none;
    list-style: none;
    padding: 10px 0 0 10px;
    margin: 0;

    li {
      float: left;
      margin-right: 10px;
      display: flex;
      align-items: center;
    }

    .calendar-legend-bullet {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      display: block;
      float: left;
      background: $status-green;

      &.status-1 {
        background: $status-blue;
      }
    }

    .calendar-legend-title {
      margin-left: 4px;
    }
  }

  // custom calendar styles
  .uib-daypicker {
    outline: none;

    table {
      width: 100%;

      thead {
        color: #6c757d;

        // calendar month selector
        tr:first-child {
          //display: none;
          button {
            background: none;
            cursor: default;
            color: #fff;
            border: none;
            font-size: 12px;
            pointer-events: none;
            padding: 5px 0 0 0;

            &.uib-left, &.uib-right {
              display: none;
            }

            strong {
              font-weight: normal;
              border-bottom: #42536b solid 1px;
            }
          }
        }

        tr:nth-child(2) {
          th {
            padding: 6px 0;
          }
        }
      }

      .uib-day {
        button {
          background: transparent;
          border: none;
          color: #fff;
          box-shadow: none;
          cursor: default;
          pointer-events: none;

          &.active {
            box-sizing: border-box;
            border: #6c757d solid 1px;
            background: #3c5575;

            span.text-info {
              color: #fff!important;
            }
          }

          span {
            &::after {
              //display: block;
              display: none;
              content: '';
              width: 5px;
              height: 5px;
              border-radius: 50%;
              margin: 0 auto;
              background: $status-green;
            }

            &.status-1 {
              &::after {
                background: $status-blue;
              }
            }
          }
        }

        @media(max-width: 1600px) {
          button {
            padding: 5px 4px;
          }
        }
      }
    }
  }
}
