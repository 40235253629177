@import '../_variables.scss';
@import '../_theme.scss';
@import '../inc/bootstrap/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss';

.insp-last-reports {

  .content-container {
    overflow-y: auto;
    padding: 0 16px;

    .reports-table {
      max-height: 360px;
      overflow-y: auto;
      color: #fff;
      width: 100%;

      .report-row {
        .report-cell {
          border-bottom: 1px solid $-panel-border-color;
          padding: 10px 0;

         &.cell-icon {
           width: 48px;

           img {
             width: 40px;
           }
         }

          &.cell-project {
            width: 100px;
          }

          &.cell-date {
            width: 120px;
          }

          &.cell-status {
            width: 160px;

            .report-status {
              display: inline-block;
              padding: 4px 8px;
              border: #0388da solid 1px;
              border-radius: 5px;

              .status-icon {
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: green; // todo: replace

                &.status- {
                  &draft {
                    background: #FFD500;
                  }
                  &published_draft {
                    background: #03C05B;
                  }
                }
              }

              .status-title {
                display: inline-block;
                margin-left: 6px;
                text-transform: capitalize;
              }
            }
          }

          &.cell-actions {
            width: 40px;
            text-align: center;

            .actions-btn {
              color: #0388DA;
              font-size: 30px;
              line-height: 5px;
              cursor: pointer;
            }
          }

          .cell-content-main {
          }

          .cell-content-sub {
            color: #a5b1b9;
          }

        }
      }
    }
  }
}
