//Write here all the variables about main theme used into main.scss

//-------------------General Variables: begin----------------------------
    $-font-family: "Roboto";
    $-hover-bg-color: #0093ee;
    $-hover-text-color: white;
    $-dropdown-border-color: #0093ee;
    $-dropdown-text-color: #0093ee;
    $-input-bg-color: #eaeaea;//rgb(243, 243, 243);
    $-status-bar-bg-color: rgba(0, 200, 0, .6);
    $-status-color: rgb(0, 200, 0);
    $-shadow-color: rgba(0,0,0,.16);

    //-------------------------Page variables: begin----------------------------
        $-page-header-bg-color: #2c405a;
        $-page-header-text-color: white;
        $-page-bg-color: #212f41;
        $-page-text-color: white;
    //-------------------------Page variables: end------------------------------

    //-------------------------Panel variables: begin----------------------------
        $-panel-header-bg-color: #144368;
        $-panel-header-text-color: white;

        $-panel-bg-color-blue: #161B2E;
        $-panel-bg-color-gray: #2c405a;

        $-panel-text-color-white: white;
        $-panel-text-color-blue: #0093ee;

        $-panel-border-color: rgba(255, 255, 255, .1);
    //-------------------------Panel variables: end------------------------------

    //-------------------------Popup variables: begin----------------------------
        $-popup-header-bg-color: #0093ee;
        $-popup-header-text-color: white;

        $-popup-bg-color: white;

        $-popup-shadow: 0 3px 6px rgba(0,0,0,.16);

        $-popup-text-color-white: white;
        $-popup-text-color-black: black;

        $-popup-border-color: rgba(255, 255, 255, 0.5);
    //-------------------------Popup variables: end------------------------------

    //-------------------------Button variables: begin----------------------------
        $-btn-bg-color-blue: #0093ee;
        $-btn-bg-color-blue-dark: #2c405a;
        $-btn-bg-color-white: white;
        $-btn-bg-color-gray-dark: rgb(25,25,25);

        $-btn-text-color-white: white;
        $-btn-text-color-blue-dark: #144368;
        $-btn-text-color-blue: #0093ee;
        $-btn-text-color-gray: gray;

        $-btn-border-color-blue: #0093ee;        
        $-btn-border-color-blue-dark: rgba(255,255,255,.1);
    //-------------------------Button variables: end------------------------------

    //-------------------------Table variables: begin----------------------------
        $-table-bg-color-blue-clear: #304865;

        $-table-bg-color-blue: #1b4a5f;
        $-table-bg-color-blue-darker: #143848;

        $-table-text-color-cyan: #009688;
            

    //-------------------------Table variables: end------------------------------


//-------------------General Variables: end------------------------------



//-------------------Main Variables: begin----------------------------
    $main-bg-color: $-page-bg-color;
    $main-text-color: $-page-text-color;
    $main-font-size: 12px;
    $main-font-family: $-font-family;
//-------------------Main Variables: end------------------------------

//-------------------Header Variables: begin----------------------------
    $header-bg-color: $-page-header-bg-color;
    $header-height: 60px;
    $header-text-color: $-page-header-text-color;
    $header-font-family: $-font-family;
    $header-font-size: 16px;    
//-------------------Header Variables: end------------------------------

//-------------------Footer Variables: begin----------------------------
    $footer-bg-color: yellow;
    $footer-height: 0px; //height when the footer is unvisible 
//-------------------Footer Variables: end------------------------------

//-------------------Side-panel Variables: begin----------------------------
   $side-panel-bg-color: $-panel-bg-color-blue;
   $side-panel-text-color: $-panel-text-color-white;
   $side-panel-font-size: 12px;
   
   $side-panel-header-bg-color: $-panel-bg-color-blue;
   $side-panel-header-font-size: 14px;
   $side-panel-header-font-weight: bold;
   $side-panel-header-color: $-panel-text-color-white;
   $side-panel-header-height: 30px;
   $side-panel-header-border-width: 0 0 1px 0;
   $side-panel-header-border-color: $-panel-border-color;
   $side-panel-header-border-style: solid;

   $side-panel-header-with-dropdown-height: 34px;
//-------------------Side-panel Variables: end------------------------------

//-------------------Inner-panel Variables: begin----------------------------
    $inner-panel-bg-color: $-panel-bg-color-gray;
    $inner-panel-text-color: $-panel-text-color-white;
    $inner-panel-font-size: $-font-family;

    $inner-panel-header-bg-color: $-panel-header-bg-color;
    $inner-panel-header-text-color: $-panel-header-text-color;
    $inner-panel-header-font-size: 15px;
    $inner-panel-header-font-weight: bold;
    $inner-panel-header-height: 40px;
    $inner-panel-header-md-height: 70px;
    $inner-panel-header-md2-height: 100px;

    $inner-panel-footer-height: 40px;
//-------------------Main-panel Variables: end------------------------------

//-------------------Ng-table Variables: begin----------------------------
    $ng-table-font-family: $-font-family;
    
    //header section
    $ng-table-header-bg-color: $-panel-bg-color-gray;
    $ng-table-header-text-color: $-panel-text-color-white;
    $ng-table-header-border-color: $-panel-border-color;
    $ng-table-header-font-size: 12px;
    $ng-table-header-font-family: $-font-family;
    $ng-table-header-font-weight: bold;    
    $ng-table-header-sorting-arrows-color: white;
    $ng-table-header-sorting-bg-color: #304865;
    
    //filter section
    $ng-table-filter-bg-color: $-panel-bg-color-gray;
    $ng-table-filter-text-color: $-panel-text-color-white;
    $ng-table-filter-font-size: 12px;
    $ng-table-filter-font-weight: bold;
    $ng-table-filter-border-color: white;
    $ng-table-filter-height: 20px;

    //body section
    $ng-table-bg-color: $-panel-bg-color-gray;
    $ng-table-text-color: $-panel-text-color-white;
    $ng-table-row-border-color: $-panel-border-color;
    $ng-table-font-size: 12px;

    //pager section
    $ng-table-pager-padding: 0 25px 0;
    $ng-table-pager-height: 55px;
    $ng-table-pager-bg-color: $-table-bg-color-blue-clear;
    $ng-table-pager-border-color: $-panel-border-color;

    //pagination section
    $ng-table-pagination-bg-color: $-table-bg-color-blue;
    $ng-table-pagination-text-color: $-panel-text-color-white;
    $ng-table-pagination-hover-bg-color: $-panel-border-color;
    $ng-table-pagination-hover-text-color: $-table-text-color-cyan;
    $ng-table-pagination-active-bg-color: $-table-bg-color-blue-darker;
    $ng-table-pagination-active-text-color: $-table-text-color-cyan;
    $ng-table-pagination-active-focus-bg-color: $-table-bg-color-blue-darker;
    $ng-table-pagination-active-focus-text-color: $-table-text-color-cyan;
    $ng-table-pagination-active-hover-bg-color: $-table-bg-color-blue-darker;
    $ng-table-pagination-active-hover-text-color: $-table-text-color-cyan;

//-------------------Ng-table Variables: end------------------------------


