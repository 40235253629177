//Write here all the general variables used into main.scss

//************************** NOTE *************************************
//-xxs, -xs, -sm, -md, -lg, -xlg means:  
//    extra extra small, extra small, small, middle, large, extra large
//*********************************************************************

//-------------------MAIN Variables: begin--------------------------
    $extra_extra_small_size: 3px;
    $extra_small_size: 5px;
    $small_size: 10px;
    $middle_size: 15px;
    $large_size: 20px;
//-------------------MAIN Variables: end----------------------------

//-------------------Padding Variables: begin--------------------------
    //variables about padding extra small... classes 
    $padding-left-xs: $extra_small_size;
    $padding-right-xs: $extra_small_size;
    $padding-top-xs: $extra_small_size;
    $padding-bottom-xs: $extra_small_size;

    //variables about padding  small... classes 
    $padding-left-sm: $small_size;
    $padding-right-sm: $small_size;
    $padding-top-sm: $small_size;
    $padding-bottom-sm: $small_size;

    //variables about padding  middle... classes 
    $padding-left-md: $middle_size;
    $padding-right-md: $middle_size;
    $padding-top-md: $middle_size;
    $padding-bottom-md: $middle_size;
//-------------------Padding Variables: end----------------------------

//-------------------Margin Variables: begin--------------------------
    //variables about margin  extra small... classes 
    $margin-left-xs: $extra_small_size;
    $margin-right-xs: $extra_small_size;
    $margin-top-xs: $extra_small_size;
    $margin-bottom-xs: $extra_small_size;

    //variables about margin  small... classes 
    $margin-left-sm: $small_size;
    $margin-right-sm: $small_size;
    $margin-top-sm: $small_size;
    $margin-bottom-sm: $small_size;

    //variables about margin  middle... classes 
    $margin-left-md: $middle_size;
    $margin-right-md: $middle_size;
    $margin-top-md: $middle_size;
    $margin-bottom-md: $middle_size;

    //variables about large  middle... classes 
    $margin-left-lg: $large_size;
    $margin-right-lg: $large_size;
    $margin-top-lg: $large_size;
    $margin-bottom-lg: $large_size;
    
//-------------------Margin Variables: end----------------------------

//-------------------Border-radius Variables: begin--------------------------
    //variables about border radius extra small... classes 
    //tl, tr, bl, br means: top-left, top-right, bottom-left, bottom-right
    $border-radius-xxs: $extra_extra_small_size;
    $border-radius-tl-xxs: $extra_extra_small_size;
    $border-radius-tr-xxs: $extra_extra_small_size;
    $border-radius-bl-xxs: $extra_extra_small_size;
    $border-radius-br-xxs: $extra_extra_small_size;

    //variables about border radius extra small... classes 
    //tl, tr, bl, br means: top-left, top-right, bottom-left, bottom-right
    $border-radius-xs: $extra_small_size;
    $border-radius-tl-xs: $extra_small_size;
    $border-radius-tr-xs: $extra_small_size;
    $border-radius-bl-xs: $extra_small_size;
    $border-radius-br-xs: $extra_small_size;


    //variables about border radius small... classes 
    //tl, tr, bl, br means: top-left, top-right, bottom-left, bottom-right
    $border-radius-sm: $small_size;
    $border-radius-tl-sm: $small_size;
    $border-radius-tr-sm: $small_size;
    $border-radius-bl-sm: $small_size;
    $border-radius-br-sm: $small_size;
//-------------------Border-radius Variables: end----------------------------


//-------------------Ng-table Variables: begin----------------------------
    $ng-table-pagination-height: 55px;
//-------------------Ng-table Variables: end------------------------------

//----------------------------------Customer Variables: begin----------------------------
    $customer-logo-width: 80px;
    $customer-logo-border-color: lightgray;

    $customer-dashboard-height: 100px;
    $customer-list-width: 250px;
    $customer-info-width: 200px;
    $customer-popup-sx-width: 130px;

//----------------------------------Customer Variables: end------------------------------

//-------------------Icons Variables: begin----------------------------
    $icon-width-xxs: 8px;
    $icon-height-xxs: 8px;

    $icon-width-xs: 12px;
    $icon-height-xs: 12px;

    $icon-width-xs2: 14px;
    $icon-height-xs2: 14px;

    $icon-width-sm: 16px;
    $icon-height-sm: 16px;

    $icon-width-md: 20px;
    $icon-height-md: 20px;


//-------------------Icons Variables: end------------------------------


//----------------------------------Asset-Data Variables: begin----------------------------
    $asset-data-list-width: 300px;
    $asset-data-tools-height: 50px;
    $images-videos-column-width: 50px;
    $images-videos-tools-height: 50px;
    $images-videos-thumbs-height: 100px;
    $asset-data-tools-width-col1: 200px;
    $asset-data-tools-width-col2: 250px;
//----------------------------------Asset-Data Variables: end------------------------------

//----------------------------------Compass Variables: begin----------------------------
    $compass-size: 90px;
//----------------------------------Compass Variables: end------------------------------
