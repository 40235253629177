@import '../_variables.scss';
@import '../_theme.scss';
@import '../inc/bootstrap/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss';

.asset-types {
  .asset-type-list, .asset-type-list-mobile {
    list-style: none;
    padding: 0 10px;
    margin: 0;
    overflow-y: auto;

    .asset-type-item {
      display: block;
      position: relative;
      padding: 8px 0;
      border-bottom: 1px solid $-panel-border-color;

      .asset-type-bar-bg {
        z-index: 1;
        display: block;
        position: absolute;
        padding: 8px 0;
        right: 0;
        height: calc(100% - 16px);
        background: #3c5575;
      }

      .asset-type-info {
        z-index: 2;
        position: relative;

        .asset-type-name {

        }

        .asset-type-icon {
          img {
            width: 36px;
            height: auto;
          }
        }
      }

      .asset-type-numbers {
        z-index: 2;
        display: block;
        position: absolute;
        padding: 8px 0;
        top: 0;
        right: 5px;

        .asset-type-number {
          display: block;
          text-align: right;
        }

        .asset-type-percent {
          display: block;
          text-align: right;
          color: #a5b1b9;
        }
      }
    }
  }
}
