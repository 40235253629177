@import '../_variables.scss';
@import '../_theme.scss';
@import '../inc/bootstrap/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss';

.owner-last-reports {

  .content-container {
    overflow: hidden;
    overflow-y: auto;

    .owner-reports {
      list-style: none;
      padding: 0 10px;
      margin: 0;
      color: #fff;

      .owner-report {
        display: inline-block;
        padding: 10px 0;
        border-bottom: 1px solid $-panel-border-color;
        width: 100%;

        .report-left {
          width: calc(100% - 140px);
          overflow: hidden;
          float: left;

          .report-title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #0388da;
            font-weight: bold;
          }

          .report-observations {
            margin-top: 4px;

            .observations-title {
              color: #a5b1b9;
            }

            .observation-circle {
              display: inline-block;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              text-align: center;
              padding: 2px;
              margin-left: 2px;
              background: #666666;

              &.red {
                background: #FC3C3C;
              }
              &.orange {
                background: #FD8C00;
                //color: #0388da;
              }
              &.yellow {
                background: #FFCE00;
                //color: #0388da;
              }
            }
          }
        }

        .report-right {
          float: right;
          width: 140px;
          overflow: hidden;

          .report-date {
            float: right;
          }

          .report-status {
            display: inline-block;
            float: right;
            clear: right;
            padding: 4px 8px;
            border: #0388da solid 1px;
            border-radius: 5px;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}
