@import '../_variables.scss';
@import '../_theme.scss';
@import '../inc/bootstrap/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss';

.dashboard {
  .dashboard-wrapper {
    padding: 20px 50px 0 50px !important;

    .inner-panel {
      margin-bottom: 35px;

      .title-action {
        .title-action-link {
          color: #0388DA;
          cursor: default;
        }
      }
    }

    .status-legend-items {
      padding: 10px;

      .status-legend-item {
        display: inline-block;
        width: 100%;
        // border-bottom: 1px solid $-panel-border-color;
        // padding: 10px 0 4px 0;
        outline: none;

        &.title-item {
          font-size: 14px;
          padding-top: 0;
        }

        .status-legend-bullet {
          float: left;
          line-height: 20px;
          border-radius: $border-radius-sm;
        }

        .status-legend-title {
          display: inline-block;
          float: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: 6px;
        }

        .status-legend-number {
          float: right;
          width: 30px;
        }

        @media(max-width: 1600px){
          .status-legend-number {
            width: 16px;
          }
        }
      }
    }

    .sf-scrollbar {
      &::-webkit-scrollbar-track {
        // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #a5b1b9;
      }

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        border-radius: 10px;
        background-color: #a5b1b9;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #0388da;
      }
    }
  }

  @import 'status-chart';
  @import 'asset-map';
  @import 'insp-last-reports';
  @import 'asset-data';
  @import 'owner-last-reports';
  @import 'asset-types';
  @import 'dashboard-calendar';
  @import 'owner-notifications';
  @import 'email-notification';


  // heights

  $padding-single: 160px;
  $padding-semi: 110px;

  .owner-last-reports {
    .content-container {
      height: calc(75vh - #{$padding-semi});
    }
  }

  .owner-notifications {
    .content-container {
      height: calc(25vh - #{$padding-semi});
    }
  }

  .asset-map-widget {
    .asset-map-container {
      height: calc(60vh - #{$padding-semi});
    }
  }

  .insp-last-reports {
    .content-container {
      height: calc(35vh - #{$padding-semi});
    }
  }

  .asset-types {
    .asset-type-list {
      max-height: calc(70vh - #{$padding-single});
    }
    .asset-type-list-mobile {
      max-height: calc(100vh  - 103px);
    }
  }

  .dashboard-asset-data{
    .content-container {
      height: calc(35vh - #{$padding-semi});
    }
  }

}
