// just importing the SCSS doesn't work, so we import the build CSS
// @import 'angular-material/angular-material.scss';

//import temporarily the old main.scss renamed main_TO-DELETE.scss
//All the css configuration will be write or moved into this file
//At the end of the refactoring all the file with prefix _TO-DELETE
//will be deleted
// @import 'main_TO-DELETE.scss';


/*
 * Load Main Bootstrap SASS files but really though
 */
 @import 'inc/bootstrap/bootstrap-sass/assets/stylesheets/bootstrap';


@import '_variables.scss';
@import '_theme.scss';
@import '_fonts.scss';
@import '_app.scss';
@import '_popup.scss';
@import '_button.scss';


//Set up html and body elements
html, body {
    height: 100%!important;
    overflow: hidden;

    :focus {
        outline: none;        
    }
}


//Set up the element with id=header into common.html
#header {
    background-color: $header-bg-color;
    color: $header-text-color;
    height: $header-height;
    font-size: $header-font-size;
    font-family: $header-font-family;
}

//Set up the element with id=footer into common.html
#footer {
    background-color: $footer-bg-color;
    height: $footer-height;
}

//Set up the element with id=main into common.html
#main {
    background-color: $main-bg-color;
    // --height: calc($header-height + $footer-height);
    height: calc(100% - #{$header-height})!important;
    height: 100%;
    font-size: $main-font-size;
    color: $main-text-color;
    font-family: $main-font-family;
}

.fontAwesome {
    font-family: Helvetica, 'FontAwesome', sans-serif;
  }

.blur {
    filter: blur(3px);
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px);
}    

//----------------Remove arrows into input element: begin----------------------------
/* Chrome, Safari, Edge, Opera */
input.hide-arrows::-webkit-outer-spin-button,
input.hide-arrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;      
}

/* Firefox */
input.hide-arrows[type=number] {
    -moz-appearance: textfield;
}
//----------------Remove arrows into input element: end------------------------------


.grab {cursor: -webkit-grab; cursor: grab;}
.grabbing {cursor: -webkit-grabbing; cursor: grabbing;}
.not-allowed {cursor: not-allowed;}

//Set up the element with id=main-content into common.html
#main-content {
    height: 100%;
}


.iframe {
    width: 100%;
    height: 100%;
}

//Change some confing from the bootstrap's class
.container-fluid {
    margin: 0!important;
    padding: 0!important;
}

//Change some confing from the bootstrap's class
.container::after, .container::before {
    content: none;
    display: none;
}

//Change some confing from the bootstrap's class
.row::after, .row::before {
    content: none;
    display: none;
}

//Change some confing from the bootstrap's class
.row {
    margin: 0!important;
    padding: 0!important;

    &.size-sm {
        height: 20px;
    }
}


//Change some confing from the bootstrap's class
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6,
.col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
    margin:0;
    padding: 0;
}

//Classes used to set up the column's height
.h-05 {
    height: 5%!important;
}
.h-10 {
    height: 10%!important;
}
.h-12 {
    height: 12%!important;
}
.h-15 {
    height: 15%!important;
}
.h-20 {
    height: 20%!important;
}
.h-30 {
    height: 30%!important;
}
.h-40 {
    height: 40%!important;
}
.h-50 {
    height: 50%!important;
}
.h-60 {
    height: 60%!important;
}
.h-70 {
    height: 70%!important;
}
.h-80 {
    height: 80%!important;
}
.h-90 {
    height: 90%!important;
}
.h-100 {
    height: 100%!important;
}

//set up padding to unset
.padding-none {
    padding: 0px;
}

//Set up the padding extra small on all the sides
.padding-xs {
    padding: $padding-top-xs $padding-right-xs $padding-bottom-xs $padding-left-xs!important;
    // padding-left: $padding-left-xs!important;
    // padding-right: $padding-right-xs!important;
    // padding-top: $padding-top-xs!important;
    // padding-bottom: $padding-bottom-xs!important;
}

//Set up the padding extra small on bottom side
.padding-bottom-xs {
    padding-bottom: $padding-bottom-xs!important;
}

//Set up the padding extra small on left and right sides
.padding-dx-sx-xs {
    padding-left: $padding-left-xs!important;
    padding-right: $padding-right-xs!important;
}

//Set up the padding extra small on left side
.padding-top-bottom-xs {
    padding-top: $padding-top-xs!important;
    padding-bottom: $padding-bottom-xs!important;
}

.padding-top-xs {
    padding-top: $padding-top-xs!important;
}

.padding-sx-xs {
    padding-left: $padding-left-xs!important;
}

.padding-dx-xs {
    padding-right: $padding-right-xs!important;
}

//Set up the padding small on all the sides
.padding-sm {
    padding: $padding-top-sm $padding-right-sm $padding-bottom-sm $padding-left-sm!important;
    // padding-left: $padding-left-sm!important;
    // padding-right: $padding-right-sm!important;
    // padding-top: $padding-top-sm!important;
    // padding-bottom: $padding-bottom-sm!important;
}

//Set up the padding medium on all the sides
.padding-md {
    padding: $padding-top-md $padding-right-md $padding-bottom-md $padding-left-md!important;
}

//Set up the padding small on left and right sides
.padding-dx-sx-sm {
    padding-left: $padding-left-sm!important;
    padding-right: $padding-right-sm!important;
}

//Set up the padding small on right side
.padding-dx-sm {
    padding-right: $padding-right-sm!important;
}

//Set up the padding small on left side
.padding-sx-sm {
    padding-left: $padding-left-sm!important;
}

//Set up the padding small on left side
.padding-top-sm {
    padding-top: $padding-top-sm!important;
}

//Set up the padding small on left side
.padding-top-bottom-sm {
    padding-top: $padding-top-sm!important;
    padding-bottom: $padding-bottom-sm!important;
}

//Set up the padding middle on left and right sides
.padding-dx-sx-md {
    padding-left: $padding-left-md!important;
    padding-right: $padding-right-md!important;
}

.padding-dx-md {
    padding-right: $padding-right-md!important;
}

.padding-sx-md {
    padding-left: $padding-left-md!important;
}

.margin-none {
    margin: 0px!important;
}
.margin-bottom-none {
    margin-bottom: 0px!important;
}

//Set up the margin extra small on bottom side
.margin-bottom-xs {
    margin-bottom: $margin-bottom-xs;
}

//Set up the margin small on bottom side
.margin-bottom-sm {
    margin-bottom: $margin-bottom-sm;
}

.margin-bottom-md {
    margin-bottom: $margin-bottom-md;
}

.margin-dx-xs {
    margin-right: $margin-right-xs;
}
.margin-dx-sx-md {
    margin-right: $margin-right-md;
    margin-left: $margin-left-md;
}

.margin-dx-md {
    margin-right: $margin-right-md;
}
.margin-sx-md {
    margin-left: $margin-left-md;
}

//Set up the margin small on right side
.margin-dx-sm {
    margin-right: $margin-right-sm;
}

//Set up the margin small on left side
.margin-sx-sm {
    margin-left: $margin-left-sm;
}

.margin-dx-sx-sm {
    margin-left: $margin-left-sm;
    margin-right: $margin-right-sm;
}
//Set up the margin small on top and bottom side
.margin-top-bottom-sm {
    margin-top: $margin-top-sm;
    margin-bottom: $margin-bottom-sm;
}

.margin-sx-lg {
    margin-left: $margin-left-lg;
}

//Set up the border radius extra small on all the sides
.border-radius-xs {
    border-radius: $border-radius-tl-xs $border-radius-tr-xs $border-radius-br-xs $border-radius-bl-xs;
}

//Set up the border radius extra small on all the sides
.border-radius-top-xs {
    border-radius: $border-radius-tl-xs $border-radius-tr-xs 0 0;
}

.my-border-color {
    &.main-color {
        border-color: $-panel-border-color!important;
    }

    &.color-1 {
        border-color: #0093ee!important;
    }

    &.color-light-gray {
        border-color: #e0e0e0!important;
    }

    &.color-light-blue {
        border-color: #324b6d!important;
    }
    
}

//Set up the border on all the sides
.my-border {
    border: 1px solid $-panel-border-color!important;

    @extend .my-border-color;
}

//Set up the border on top side
.my-border-top {
    border-top: 1px solid $-panel-border-color;

    @extend .my-border-color;
}

//Set up the border on bottom side
.my-border-bottom {
    border-bottom: 1px solid $-panel-border-color;

    @extend .my-border-color;
}

//Set up the border on right side
.my-border-right {
    border-right: 1px solid $-panel-border-color;

    @extend .my-border-color;
}

//Set up the border on left side
.my-border-left {
    border-left: 1px solid $-panel-border-color;

    @extend .my-border-color;
}

//set up the icons
.icon-xxs {
    width: $icon-width-xxs;
    height: $icon-height-xxs;
}

.icon-xs {
    width: $icon-width-xs;
    height: $icon-height-xs;
}

.icon-xs2 {
    width: $icon-width-xs2;
    height: $icon-height-xs2;
}

.icon-sm {
    width: $icon-width-sm;
    height: $icon-height-sm;
}

.icon-md {
    width: $icon-width-md;
    height: $icon-height-md;
}

.icon-lg {
    width: 28px;
    height: 28px;
}


//use this class to set up the element :hover
.my-hover {
    background-color: $-hover-bg-color;
    color: $-hover-text-color;
}


//Set up the border on bottom side
.checklist-box {
    border-color: #2c405a;
    border-style: solid;
    border-width: 1px;
    border-radius:5px;
    border-color: gray;
    width: 99%;


    @extend .my-border-color;
}





.inner-panel {
    &.background-transparent {
        background-color: rgba($inner-panel-bg-color,0.8);
        >.header {
            // background-color: rgba(0,0,0,.5);
            background-color: rgba($inner-panel-header-bg-color,0.8);
        }
    }

    background-color: $inner-panel-bg-color;
    color: $inner-panel-text-color;
    font-size: $inner-panel-font-size;

    >.header {
        background-color: $inner-panel-header-bg-color;
        font-size: $inner-panel-header-font-size;
        // font-weight: $inner-panel-header-font-weight;
        color: $inner-panel-header-text-color;
        height: $inner-panel-header-height;
        line-height: $inner-panel-header-height;
        &.hidden {
            visibility: hidden;
        }


        &.header-md {
            height: $inner-panel-header-md-height;

            .header-info {
                height: $inner-panel-header-md-height - $inner-panel-header-height;
            }    
        }
        &.header-md2 {
            height: $inner-panel-header-md2-height;

            .header-info {
                height: $inner-panel-header-md2-height - $inner-panel-header-height;
            }    
        }

        &.header-tools {
            height: $inner-panel-header-height;
        }


        .myInput {
            @extend .myInput;
            background-color: #2c405a!important;
            color: #2196f3!important;
            line-height: 0;

            &.size-sm {
                font-size: 12px;
                height: 25px;
            }
        }
    }

    >.body {
        &.with-header.with-footer {
            height: calc(100% - (#{$inner-panel-header-height} + #{$inner-panel-header-height})) ;
        }
    }

    >.footer {
        height: $inner-panel-footer-height;
    }

}


.side-panel {
    background-color: $side-panel-bg-color;
    color: $side-panel-text-color;
    font-size: $side-panel-font-size;

    >.header {
        background-color: $side-panel-header-bg-color;
        font-size: $side-panel-header-font-size;
        font-weight: $side-panel-header-font-weight;
        color: $side-panel-header-color;
        height: $side-panel-header-height;
        border-width: $side-panel-header-border-width;
        border-style: $side-panel-header-border-style;
        border-color: $side-panel-header-border-color;
    }

    >.header.width-dropdown {
        height: $side-panel-header-with-dropdown-height;
    }
}




.input-search {
    margin-bottom: 0px;
    margin-top: 10px;
    height: 40px;
    label {
        background: url(/img/icons/search.png);
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        float: left;
    }
    input.search {
        width: 85%;
        display: inline-grid;
        border-width: 0;
        border-bottom-width: thin;
        border-bottom-color: lightgray;
        background-color: #ffffff00;
    }
}

.list-group {
    margin-bottom: 0px;
    .row {
        display: flex;
        width: 100%;
        height: auto;
        margin: 0px;
        margin-bottom: 4px;
        &:hover {
            @extend .my-hover;

            font-weight: bold;
            cursor: pointer;
        }
    }
    .row.selected {
        background-color: $-hover-bg-color;
        color: $-hover-text-color;
        font-weight: bold;
    }
    a {
        color: black;
        cursor: pointer;
    }
}

.list-group-popup {
    @extend .list-group;

    border: 1px solid #f3f3f3;
    padding: 5px;

    .row {
        border-bottom: 1px solid #f3f3f3;

        &:hover {
            font-weight: normal;
            cursor: default;
            background-color: transparent;
            color: black;
        }
    }
}

//make this check because I don't want to use
//the .my-text-truncate class into IE
//because it doesn't work properly
//-webkit-box-orient: vertical It doesn't supported by IE
// @supports (-webkit-box-orient: vertical) {
    .my-text-truncate {
        display: flex;

        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

    }
// }


//chart setting
.chart-container {
    width: calc(100% - 1px); //this make the chart responsive
    height: calc(90% - 1px);
}


// .width-auto {
//     width: auto;
// }

// .align-items-center {
//     -webkit-box-align: center!important;
//     -ms-flex-align: center!important;
//     align-items: center!important;
// }

// .align-items-top {
//     -webkit-box-align: flex-start!important;
//     -ms-flex-align: flex-start!important;
//     align-items:  flex-start!important;
// }

// .inline {
//     display: inline-flex;
// }

.cursor-pointer {
    cursor: pointer;
}


//set up an input element
.myInput {
    height: initial;
    padding: $padding-top-xs $padding-right-sm $padding-bottom-xs $padding-left-sm;
    border: none;
    width: 100%;
    background-color: $-input-bg-color;
    color: black;
    border-radius: $border-radius-xs;

    &.disabled {
        background-color: #828282;
        color: white;
    }

}

.mySelect {
    height: initial;
    padding: $padding-top-xs $padding-right-sm $padding-bottom-xs $padding-left-sm;
    border: none;
    width: 100%;
    background-color: $-input-bg-color;
    color: black;
    border-radius: $border-radius-xs;
}

.reach-text {
    max-height: 4em!important;
    min-height: 4em;
    background-color: #f7f7f7!important;
    text-align: left!important;
  }

.myLabel {
    font-family: $-font-family;
    font-weight: bold;
    color: black;

    &.color-white {
        color: white;
    }

    &.color-blue {
        color: rgb(0, 147, 238);
    }

    &.color-red {
        color: red;
    }

    &.color-gray {
        color: gray;
    }

    &.color-green {
        color: green;
    }

    &.background-dark {
        background: #212f41;
    }

    &.required:after {
        color: red;
        content: " *";
    }

    &.no-bold {
        font-weight: normal;
    }

    &.size-sm {
        font-size: 12px;
    }

    &.size-sm2 {
        font-size: 14px;
    }

    &.size-md {
        font-size: 16px;
    }

    &.size-lg {
        font-size: 20px;
    }

    &.not-selected {
        color: gray;
    }

    &.active {
        color: rgb(0, 147, 238);
    }
    &.width-with-checkbox {
        width: calc(100% - 20px)
    }
    &.underline{
        text-decoration: underline;
    }   
}

.myDisplay {
    &.inline-flex {
        display: inline-flex;
    }
    &.flex {
        display: flex;
    }
    &.inline {
        display: inline;
    }
    &.contents {
        display: contents;
    }

}


.myError {
    text-align: left;
    font-size: 10px;
    color: red;
}

.multiselectDropdown {
    width: 100%;

    input {
        opacity: 1;
    }

    .dropdown-menu {
        background-color: $-input-bg-color;
    }

    .dropdown-menu>li>a {
        padding: 0px 17px;
        cursor: pointer;
    }

    .btn-group {
        box-shadow: none;
        height: 25px;
        width: 100%;
        color: black;
        background-color: $-input-bg-color;
        padding: 4px 20px 4px 10px;
        cursor: pointer;
        border-bottom: 0;
        border-radius: $border-radius-xs;
    }
    .btn-group:before {
        margin-left: 2px;
        border-top: 4px dashed;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;

        background-color: transparent;
        position: absolute;
        top: 11px;
        right: 5px;
        content: "";
        height: 0;
        width: 0;
    }
    .glyphicon-ok:before {
        content: url('/img/icons/checked.png');

    }

    .glyphicon-remove:before {
        content: url('/img/icons/cancel.png');
    }

}

.multiselectDropdownAssetStatus {
    @extend .multiselectDropdown;

    .glyphicon {
        font-family: unset;
        line-height: unset;
        display: unset;
    }
    .glyphicon-ok:before {
        content: "";
    }
}


//***************************** SCROLLBAR Begin *********************************
    .scrollbar-container {
        height: 100%;
        // display: table;
        width: 100%;

        &.with-input-search {
            height: calc(100% - 50px);
        }

        &.with-header {
            height: calc(100% - #{$inner-panel-header-height});
        }

        &.with-header-md {
            height: calc(100% - #{$inner-panel-header-md-height});
        }

        &.with-header-md2 {
            height: calc(100% - #{$inner-panel-header-md2-height});
        }

        &.with-header.with-footer {
            height: calc(100% - (#{$inner-panel-header-height} + #{$inner-panel-header-height})) ;
        }

    }

    .scrollbar {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;

        &.with-table {
            height: calc(100% - #{$ng-table-pagination-height})!important;
        }

        &.position-relative {
            position: relative;
        }

        // &.with-input-search {
        //     height: calc(100% - 50px);
        // }

    }


    /*
    *  STYLE 1
    */

    .style-1::-webkit-scrollbar-track {
        // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #FFFFFF;
    }

    .style-1::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        border-radius: 10px;
        background-color: #FFFFFF;
    }

    .style-1::-webkit-scrollbar-thumb {
        border-radius: 10px;
        // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color:#0093ee;
    }
//***************************** SCROLLBAR End *********************************


//***************************** MAIN-TABLE NG-TABLE Begin *********************************
.main-table {
    &.background-transparent {
        .ng-table {
            background-color: rgba($ng-table-filter-bg-color,0)!important;
        }    
        .ng-table thead tr th {
            background-color: rgba($ng-table-header-bg-color,0);
        }    
        .ng-table tbody {
            background-color: rgba($ng-table-bg-color,0);
        }    
    } 

    &.hide-pager {
        [ng-table-pagination] {
            display: none;
        }
    }

    &.hide-title {
        thead {
            display: none;
        }
    }

    &.hide-td-border {
        tbody>tr>td {
            border: 0!important;
        }
    }

    .ng-table {
        background-color: $ng-table-filter-bg-color!important;
        color: $ng-table-text-color;
        table-layout: fixed; //need to use text-overflow:ellipsis (see .ng-table th, .ng-table td)
        font-family: $ng-table-font-family;
    }

    //Define the table header row style
    .ng-table thead tr  {
        // border-bottom: 2px solid $ng-table-header-border-color;
        border-bottom: none;
    }

    //Define the table header columns style
    .ng-table thead tr th {
        background-color: $ng-table-header-bg-color;
        color: $ng-table-header-text-color;
        font-size: $ng-table-header-font-size;
        font-weight: $ng-table-header-font-weight;
        font-family: $ng-table-header-font-family;
        // border-bottom: 1px solid $-bg-color-inner-panel;
        border-bottom: none;
    }

    //Define the table header border-right
    //for all the columns but last one
    .ng-table thead tr th:not(:last-child) {
        border-right: 1px solid $-panel-border-color;
        // border-right-width: $-border-width;
        // border-right-style: $-border-style;
        // border-right-color: $-border-color;
    }

    //set up truncate text into header and body columns
    .ng-table th, .ng-table td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .table>thead>tr>th {
        border-bottom: 1px solid $-panel-border-color;
    }
    //set up the table body
    .ng-table tbody {
        background-color: $ng-table-bg-color;
        font-size: $ng-table-font-size;
    }

    //set up filter
    .ng-table .filter {
        background-color: $ng-table-filter-bg-color;
        border-left: 0!important;
    }

    //set up filter
    .ng-table th.filter  {
        padding-top: $padding-top-xs;
        padding-bottom: $padding-bottom-xs;
    }

    //set up filter input field
    .ng-table th.filter .input-filter {
        background-color: $ng-table-filter-bg-color;
        color: $ng-table-filter-text-color;
        font-size: $ng-table-filter-font-size;
        font-weight: $ng-table-filter-font-weight;
        border-bottom: 1px solid $ng-table-filter-border-color;
        border-width: 0 0 1px 0;
        // border-bottom: none;
        height: $ng-table-filter-height;
        min-height: 0!important;
    }

    //set up the table columns
    .ng-table>tbody>tr:not(.details)>td {
        border-bottom: 1px solid $ng-table-row-border-color;
    }


    //set sort arrow-up color
    .ng-table th.sortable .sort-indicator:after, .ng-table th.sortable .sort-indicator:before {
        border-color: $ng-table-header-sorting-arrows-color transparent!important;
    }

    //set sort arrow-down color
    .ng-table th.sortable .sort-indicator:before {
        border-top: 4px solid $ng-table-header-sorting-arrows-color!important;
    }

    //column to be sorted selected
    .ng-table th.sortable.sort-asc, .ng-table th.sortable.sort-desc {
        background-color: $ng-table-header-sorting-bg-color!important;//#143848!important;
    }

    //bar that contains pagination and counts sections
    .ng-table-pager {
        padding: $ng-table-pager-padding;
        max-height: $ng-table-pager-height;
        min-height: $ng-table-pager-height;
        // border-top: 1px solid $ng-table-pager-border-color;
        // border-bottom: 1px solid $ng-table-pager-border-color;


    }

    //------------------------------------- ng-table details: begin ----------------------------------
        .details {
            background-color: rgba(0,0,0,.2);

            td:first-child {
                border-top: 1px solid rgba(0,0,0,0)!important;
            }
        }

        .ng-table>tbody>tr.details {
            border-bottom: 1px solid $ng-table-row-border-color;

        }

        .ng-table>tbody>tr>td {
            border-top:none!important;
        }
    //------------------------------------- ng-table details: end ------------------------------------

    //------------------------------------- ng-table-pagination: begin ----------------------------------
        //ng-table-pagination is the ng-table section about the number of pages
        //Each ng-table-pagination element is defined as a ul/li/a

        //set up table pagination position
        .ng-table-pagination {
            margin-top: 18px!important;
            // box-shadow: 0 1px 4px 0 rgba(0,0,0,.3);
            display: inline-flex!important;
        }

        //set up table pagination margin among the page numbers
        .ng-table-pagination>li {
            margin: 0 2px 0 0;
        }

        //set up table pagination page numbers
        .ng-table-pagination>li>a, .ng-table-pagination>li>span {
            border-radius: 0!important;
            padding: 0;
            width: 37px;
            height: 25px;
            line-height: 27px;
            text-align: center;
            font-size: 12px;
            z-index: 1;
            position: relative;
            border: 0;
            background: $ng-table-pagination-bg-color;
            color: $ng-table-pagination-text-color;
        }

        //set up table pagination page number selected
        .ng-table-pagination>.active>a {
            outline: none;
            color: $ng-table-pagination-active-text-color!important;
            background-color: $ng-table-pagination-active-bg-color!important;
        }
        .ng-table-pagination>.active>a:focus {
            outline: none;
            color: $ng-table-pagination-active-focus-text-color!important;
            background-color: $ng-table-pagination-active-focus-bg-color!important;
        }

        .ng-table-pagination>.active>a:hover {
            color: $ng-table-pagination-active-hover-text-color!important;
            background-color: $ng-table-pagination-active-hover-bg-color!important;
        }
        .ng-table-pagination>li>a:before, .ng-table-pagination>li>span:before {
            border-radius: 0!important;
        }

        .ng-table-pagination>li>a:hover {
            color: $ng-table-pagination-hover-text-color;
            background: $ng-table-pagination-hover-bg-color!important;
        }
    //------------------------------------- ng-table-pagination: end ------------------------------------


    //------------------------------------- ng-table-counts: begin ----------------------------------
        //ng-table-counts is the ng-table section about the number of results
        //that are shown into the table.
        //Each ng-table-counts element is defined as a button

        .ng-table-counts {
            margin-top: 18px;
        }

        .ng-table-counts button {
            margin: 0 1px 0 0!important;
            height: 25px;
            line-height: 0;
            font-size: 12px;
            background-color: #1b4a5f!important;
            color: white;
            border-color: transparent!important;
        }

        .ng-table-counts button.active {
            outline: none;
            color: #009688!important;
            background-color: #143848!important;
            border-color: transparent!important;
        }
        .ng-table-counts button.active:focus {
            outline: none;
            color: #009688!important;
            background-color: #143848!important;
            border-color: transparent!important;
        }

        .ng-table-counts button.active:hover {
            color: #009688!important;
            background-color: #143848!important;
            border-color: transparent!important;
        }

        .ng-table-counts button:hover {
            color: #009688;
            background-color: rgba(0,0,0,.1)!important;
            border-color: transparent!important;
        }
    //------------------------------------- ng-table-counts: end ------------------------------------

    &.external-pagination {
        position: absolute;
        width: 100%;
        bottom: 0;
    }

    &.external-pagination-mobile {
        position: absolute;
        width: 100%;
        bottom: 50px;
    }

}


//***************************** MAIN-TABLE NG-TABLE End ***********************************


//***************************** POPUP-TABLE NG-TABLE Begin *********************************
.popup-table {

    &.hide-pager {
        [ng-table-pagination] {
            display: none;
        }
    }

    &.hide-title {
        thead {
            display: none;
        }
    }

    &.hide-td-border {
        tbody>tr>td {
            border: 0!important;
        }
    }

    .ng-table {
        background-color: $ng-table-filter-bg-color!important;
        color: $ng-table-text-color;
        table-layout: fixed; //need to use text-overflow:ellipsis (see .ng-table th, .ng-table td)
        font-family: $ng-table-font-family;
    }

    //Define the table header row style
    .ng-table thead tr  {
        // border-bottom: 2px solid $ng-table-header-border-color;
        border-bottom: none;
    }

    //Define the table header columns style
    .ng-table thead tr th {
        background-color: $-input-bg-color;
        color: black;
        font-size: $ng-table-header-font-size;
        font-weight: $ng-table-header-font-weight;
        font-family: $ng-table-header-font-family;
        // border-bottom: 1px solid $-bg-color-inner-panel;
        border-bottom: none;
    }

    //Define the table header border-right
    //for all the columns but last one
    .ng-table thead tr th:not(:last-child) {
        border-right: 1px solid #e0e0e0;
        // border-right-width: $-border-width;
        // border-right-style: $-border-style;
        // border-right-color: $-border-color;
    }

    //set up truncate text into header and body columns
    .ng-table th, .ng-table td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .table>thead>tr>th {
        border-bottom: 1px solid $-panel-border-color;
    }
    //set up the table body
    .ng-table tbody {
        background-color: white;
        color:black;
        border: 1px solid #e0e0e0;
        font-size: $ng-table-font-size;
    }

    //set up filter
    .ng-table .filter {
        background-color: $ng-table-filter-bg-color;
        border-left: 0!important;
    }

    //set up filter
    .ng-table th.filter  {
        padding-top: $padding-top-xs;
        padding-bottom: $padding-bottom-xs;
    }

    //set up filter input field
    .ng-table th.filter .input-filter {
        background-color: $ng-table-filter-bg-color;
        color: $ng-table-filter-text-color;
        font-size: $ng-table-filter-font-size;
        font-weight: $ng-table-filter-font-weight;
        border-bottom: 1px solid $ng-table-filter-border-color;
        border-width: 0 0 1px 0;
        // border-bottom: none;
        height: $ng-table-filter-height;
        min-height: 0!important;
    }

    //set up the table columns
    .ng-table>tbody>tr:not(.details)>td {
        border-bottom: 1px solid #e0e0e0;
    }


    //set sort arrow-up color
    .ng-table th.sortable .sort-indicator:after, .ng-table th.sortable .sort-indicator:before {
        border-color: black transparent!important;
    }

    //set sort arrow-down color
    .ng-table th.sortable .sort-indicator:before {
        border-top: 4px solid black!important;
    }

    //column to be sorted selected
    .ng-table th.sortable.sort-asc, .ng-table th.sortable.sort-desc {
        background-color: #dadada!important;
    }

    //bar that contains pagination and counts sections
    .ng-table-pager {
        padding: $ng-table-pager-padding;
        max-height: $ng-table-pager-height;
        min-height: 30px;
        // background: $ng-table-pager-bg-color;
        border-top: 1px solid $ng-table-pager-border-color;
        border-bottom: 1px solid $ng-table-pager-border-color;


    }

    //------------------------------------- ng-table details: begin ----------------------------------
        .details {
            background-color: rgba(0,0,0,.2);

            td:first-child {
                border-top: 1px solid rgba(0,0,0,0)!important;
            }
        }

        .ng-table>tbody>tr.details {
            border-bottom: 1px solid $ng-table-row-border-color;

        }

        .ng-table>tbody>tr>td {
            border-top:none!important;
        }
    //------------------------------------- ng-table details: end ------------------------------------

    //------------------------------------- ng-table-pagination: begin ----------------------------------
        //ng-table-pagination is the ng-table section about the number of pages
        //Each ng-table-pagination element is defined as a ul/li/a

        .pagination {
            margin: 0px;
            float: left;
        }

        //set up table pagination position
        .ng-table-pagination {
            margin-top: 0px!important;
            // box-shadow: 0 1px 4px 0 rgba(0,0,0,.3);
            display: inline-flex!important;
        }

        //set up table pagination margin among the page numbers
        .ng-table-pagination>li {
            margin: 0 2px 0 0;
        }

        //set up table pagination page numbers
        .ng-table-pagination>li>a, .ng-table-pagination>li>span {
            border-radius: 0!important;
            padding: 0;
            width: 37px;
            height: 25px;
            line-height: 27px;
            text-align: center;
            font-size: 12px;
            z-index: 1;
            position: relative;
            border: 0;
            background: #e0e0e0;
            color: black;
            }

        //set up table pagination page number selected
        .ng-table-pagination>.active>a {
            outline: none;
            color: black!important;
            background-color: #dadada!important;
            }
        .ng-table-pagination>.active>a:focus {
            outline: none;
            color: black!important;
            background-color: #dadada!important;
            }

        .ng-table-pagination>.active>a:hover {
            color: black!important;
            background-color: #dadada!important;
            }
        .ng-table-pagination>li>a:before, .ng-table-pagination>li>span:before {
            border-radius: 0!important;
        }

        .ng-table-pagination>li>a:hover {
            color: black!important;
            background-color: #dadada!important;
            }
    //------------------------------------- ng-table-pagination: end ------------------------------------


    //------------------------------------- ng-table-counts: begin ----------------------------------
        //ng-table-counts is the ng-table section about the number of results
        //that are shown into the table.
        //Each ng-table-counts element is defined as a button

        .ng-table-counts {
            margin-top: 0px!important;
        }

        .ng-table-counts button {
            margin: 0 1px 0 0!important;
            height: 25px;
            line-height: 0;
            font-size: 12px;
            background-color: #e0e0e0!important;
            color: black;
            border-color: transparent!important;
        }

        .ng-table-counts button.active {
            outline: none;
            color: black!important;
            background-color: #dadada!important;
            -webkit-box-shadow: none!important;
            box-shadow: none!important;
            border-color: transparent!important;
        }
        .ng-table-counts button.active:focus {
            outline: none;
            color: black!important;
            background-color: #dadada!important;
            border-color: transparent!important;
        }

        .ng-table-counts button.active:hover {
            color: black!important;
            background-color: #dadada!important;
            border-color: transparent!important;
        }

        .ng-table-counts button:hover {
            color: black!important;
            background-color: #dadada!important;
            border-color: transparent!important;
        }
    //------------------------------------- ng-table-counts: end ------------------------------------
    }






// .popup-table {
    // @extend .main-table;

    // .ng-table thead tr th {
    //     background-color: $-input-bg-color;
    //     color: black;
    // }

    // .ng-table tbody {
    //     background-color: white;
    //     color:black;
    //     border: 1px solid #e0e0e0;
    // }

    // .ng-table>tbody>tr:not(.details)>td {
    //     border-bottom: 1px solid #e0e0e0;
    // }

    // .ng-table thead tr th:not(:last-child) {
    //     border-right: 1px solid #e0e0e0;
    // }

    // .ng-table thead  {
    //     border-right: 1px solid #e0e0e0;
    //     border-left: 1px solid #e0e0e0;
    // }

    //set sort arrow-up color
    // .ng-table th.sortable .sort-indicator:after, .ng-table th.sortable .sort-indicator:before {
    //     border-color: black transparent!important;
    // }

    //set sort arrow-down color
    // .ng-table th.sortable .sort-indicator:before {
    //     border-top: 4px solid black!important;
    // }

    // .ng-table th.sortable.sort-asc, .ng-table th.sortable.sort-desc {
    //     background-color: #dadada!important;
    // }

    // div[ng-table-pagination] {
    //     height: 30px;
    // }
    // .ng-table-pager {
    //     min-height: 30px;
    // }

    // .pagination {
    //     margin: 0px;
    //     float: left;
    // }
    // .ng-table-pagination {
    //     margin-top: 0px!important;
    // }

    //set up table pagination page numbers
    // .ng-table-pagination>li>a, .ng-table-pagination>li>span {
    //     background: #e0e0e0;
    //     color: black;
    // }

    //set up table pagination page number selected
    // .ng-table-pagination>.active>a {
    //     outline: none;
    //     color: black!important;
    //     background-color: #dadada!important;
    // }
    // .ng-table-pagination>.active>a:focus {
    //     outline: none;
    //     color: black!important;
    //     background-color: #dadada!important;
    // }

    // .ng-table-pagination>.active>a:hover {
    //     color: black!important;
    //     background-color: #dadada!important;
    // }

    // .ng-table-pagination>li>a:hover {
    //     color: black!important;
    //     background-color: #dadada!important;
    // }

    // .ng-table-counts {
    //     margin-top: 0px!important;
    // }

    // .ng-table-counts button {
    //     background-color: #e0e0e0!important;
    //     color: black;
    // }

    // .ng-table-counts button.active {
    //     color: black!important;
    //     background-color: #dadada!important;
    //     -webkit-box-shadow: none!important;
    //     box-shadow: none!important;
    // }
//     .ng-table-counts button.active:focus {
//         color: black!important;
//         background-color: #dadada!important;
//     }

//     .ng-table-counts button.active:hover {
//         color: black!important;
//         background-color: #dadada!important;
//     }

//     .ng-table-counts button:hover {
//         color: black!important;
//         background-color: #dadada!important;
//     }
// }
//***************************** POPUP-TABLE NG-TABLE End ***********************************



//***************************** DROPDOWN Begin ***********************************
    .my-dropdown-wrapper {
        position: absolute;
    }

    //this class extend the bootstrap class dropdown-toggle
    .my-dropdown-toggle {
        @extend .dropdown-toggle;

        background-color: $-panel-bg-color-gray;
        text-decoration: none;

        .title {
            background-color: transparent;
            // border: 1px solid $-border-color;
            color: $-dropdown-text-color;
            font-size: 12px;
            font-weight: normal;

            &.size-bg {
                font-size: 18px;
                font-weight: 700;    
            }

            &.color-white {
                color: white;
            }
        }



        &.without-arrow {
            @extend .my-dropdown-toggle;

            &:after {
                content: none;
            }
        }

        &.without-background {
            background-color: unset;
        }


    }

    .my-dropdown-toggle:hover {
        // background-color: rgba($-panel-bg-color-gray, .7);
        opacity: .7!important;
    }

    // .my-dropdown-toggle.without-arrow {
    //     @extend .my-dropdown-toggle;

    //     &:after {
    //         content: none;
    //     }
    // }

    //this class extend the bootstrap class dropdown-menu
    .my-dropdown-menu {
        @extend .dropdown-menu;

        background-color: $-panel-bg-color-gray;
        min-width: auto;
        border: 1px solid $-dropdown-border-color;
        right: auto!important;

        &.clear {
            background-color: white;

            >li>a {
                color: black;
            }
        }

        .selected  {
            color: $-panel-text-color-white;
        }

        &.drag-right-120 {
            right: -120px!important;
        }

        &.drag-left-60 {
            left: -60px!important;
        }
        &.drag-left-120 {
            left: -120px!important;
        }

        &.drag-left-80 {
            left: -80px!important;
        }
        &.drag-left-85 {
            left: -85px!important;
        }

        &.drag-left-110 {
            left: -110px!important;
        }

        &.size-xs {
            width: 100px;
        }

        &.size-sm {
            width: 140px;
        }

        &.size-md {
            width: 240px;
        }

        &.size-md2 {
            width: 260px;
        }

        &.left {
            right: 0!important;
        }

        >li>a {
            color: $-dropdown-text-color;
        }

        >li>a:hover {
            @extend .my-hover;
        }
        >li>div:hover {
            @extend .my-hover;
        }
    
    }



    //set up dropdown elements
    // .my-dropdown-menu>li>a {
    //     color: $-panel-text-color-white;
    // }

    // .my-dropdown-menu>li>a:hover {
    //     @extend .my-hover;
    // }
    // .my-dropdown-menu>li>div:hover {
    //     @extend .my-hover;
    // }

//***************************** DROPDOWN End *************************************



//***************************************** GOOGLE MAP Begin **********************************************
    //set up the fullscreen button
    .gm-fullscreen-control {
        background-color: #26374C!important;
        border: 1px solid #0093ee!important;
        height: 30px!important;
        width: 30px!important;
        right: 40px!important;
        bottom: 25px!important;
    }

    //set up the zoom-in zoom-out buttons
    .gm-bundled-control-on-bottom {
        bottom: 85px!important;
        div div {
            background-color: transparent!important;
            box-shadow: unset!important;
            width: 30px!important;
        }
        button {
            background-color: #26374C!important;
            border: 1px solid #0093ee!important;
            height: 30px!important;
            width: 30px!important;
            left: 10px!important;
        }
    }

    //set up the map/satellite buttons
    .gm-style-mtc {
        div[role=button], div:not([role]) {
            background-color: #26374C!important;
            border: 1px solid #0093ee!important;
            color: white!important;
            height: 30px!important;
            font-size: 14px!important;
            font-family: $-font-family;
            font-weight: normal!important
        }
    }

    .gm-style .gm-style-iw-c {
        padding: 0px!important;
        border: 1px solid #0093ee;
    }

    //set infowindow scrollbar 
    .gm-style .gm-style-iw-d {
        overflow: hidden !important;
    }

    .gm-style-iw.gm-style-iw-c {
        button {
            top: 0px!important;
            right: 0px!important;
            opacity: 1!important;
        }
    }

    .gm-style .gm-style-iw-t::after {
        border-bottom: 1px solid #0093ee;
        border-left: 1px solid #0093ee;
    }

    .marker {
        &.infowindow-sm {
            overflow: hidden; 
            width: 300px;
            max-height: 240px;
            color:black;
            background: white;
        }
    
        .img-sm {
            max-width: 300px;
            max-height: 170px;
        }

        .header {
            background: #0093ee;
            color: white;
            height: 30px;
        }


    }
    

//***************************************** GOOGLE MAP End ************************************************




.content-grid-10 {
    max-height: 300px;
    overflow-y: auto;
}


//************************************** TEXT AREA begin *************************************
    .ta-scroll-window  {
        min-height: 250px!important;
        max-height: 250px!important;
        overflow: hidden!important;

    }

    .ta-scroll-window > .ta-bind {
        min-height: 250px!important;
        max-height: 250px!important;
    }

    .ta-toolbar {
        .btn {
            padding: 3px 7px!important;
        }
    }

    .ta-footer {
        width: 100%;
        padding: 10px 0;
    }
//************************************** TEXT AREA end ***************************************

//************************************** RADIO BUTTON begin *************************************
    md-radio-button {
        margin-bottom: 0px
    }
    md-radio-group:not([disabled]).md-primary .md-on {
        background-color: rgb(0, 147, 238);
    }

    md-radio-button .md-off,
    md-radio-group:not([disabled]).md-primary .md-checked .md-off {
        border-color: rgb(0, 147, 238);
    }
//************************************** RADIO BUTTON end ***************************************


//************************************** CHECKBOX begin *************************************
    md-checkbox {
        margin-bottom: 0px;

        .md-label {
            margin-left: 0px;
        }
    }


    md-checkbox:not([disabled]).md-primary:not(.md-checked) .md-icon {
        border-color: rgb(0, 147, 238);
    }

    md-checkbox:not([disabled]).md-primary.md-checked .md-icon {
        background-color: rgb(0, 147, 238);
    }

    md-checkbox.delete:not([disabled]).md-primary:not(.md-checked) .md-icon  {
        border-color: rgb(255, 0, 0);
    }

    md-checkbox.delete:not([disabled]).md-primary.md-checked .md-icon  {
        background-color: rgb(255, 0, 0);
    }


    md-checkbox.md-checked .md-icon::after {
        display: inline;
    }
//************************************** CHECKBOX end ***************************************

//************************************** UPLOAD begin *************************************
    .uploader {
        &.container {
            width: 100%;
            border:1px dashed lightgray;
            height: 150px;

            &.height-md {
                height: 100px;
            }
        }
        .btn-on-bottom {
            position: absolute;
            bottom: 0px;
            width: 100%;
            left: 0px;
        }
        &.btn-cancel {
            background-color: transparent;
            border-radius: 9px;
            width: 18px;
            height: 18px;
            border: 1px solid #d3d3d3;     
        }

        &.progress-bar {
            width: 100%;
            height: 20px;
            position: relative;

            .slider-container {
                position: absolute;
                width: 100%; 
                height: 20px; 
                background-color:  #B4B9C6;    
            }

            .slider-full {
                width: 0%;
                height:20px; 
                border: 0px solid #B4B9C6;   
                background-color: #0093ee;
                &.color-red {
                    background-color: red;
                }
            }

            .text {
                width: 100%; 
                color: white; 
                position: inherit;
                top: 2px;
            }
        }

        &.progress-bar-thin {
            width: 100%;
            height: 10px;
            position: relative;

            .slider-container-thin {
                position: absolute;
                width: 100%; 
                height: 5px; 
                background-color:  #B4B9C6;    
                margin-top: 0.5%;
            }

            .slider-full-thin {
                width: 0%;
                height:5px; 
                border: 0px solid #B4B9C6;   
                background-color: #0093ee;
                &.color-red {
                    background-color: red;
                }
            }

            .text {
                width: 100%; 
                color: white; 
                position: inherit;
                top: 2px;
            }

        }

    }


    .tooltip.customClass .tooltip-inner {
        color: #333;
        background-color: white;
        background: white;
         
        opacity: 1;
        filter: alpha(opacity=1000);
        text-align: left;

    }
    /* Hide arrow */
    .tooltip.customClass .tooltip-arrow {
        display: none;
    }

    .tooltip.customClass {
        opacity: 1;
        position: absolute;
    }

//************************************** UPLOAD end ***************************************

//************************************** HEADER TOOL BAR begin ***************************************

.header-nav {
    @media only screen and (min-width: 1600px) {
        .sf-nav-container {
            padding-right: 50px;
            float: right;
        }
    }
    
    .sf-nav-container {
        display: flex;
        align-items: center;
        justify-content: center;
        float: none;
        font-size: 14px;
        font-family: "Roboto Medium";
        line-height: 60px;
    }
    
    .sf-nav.sf-nav-tabs {
        line-height: 60px;
    }
    
    .sf-nav.sf-nav-tabs li {
        float: left;
        position: relative;
        height: 60px;
    }
    
    @media only screen and (min-width: 1024px) {
        .sf-nav-menu-item {
            padding: 0 20px!important;
        }
    }
    
    .sf-nav-menu-item {
        position: relative;
        padding: 0 10px;
        cursor: pointer;

    }
    
    // @media only screen and (min-width: 1024px) {
        .sf-nav.sf-nav-tabs li:after {
            display: block;
            content: "";
            height: 3px;
            width: 100%;
            position: absolute;
            background: #1d4d73;
            z-index: 101;
            left: 0;
            right: 0;
            top: calc(100% + 1px);
        }
    // }
    
    .sf-nav.sf-nav-tabs li a {
        display: inline-block;
        width: 100%;
        height: 100%;
        text-transform: uppercase;
        color: white;
    }
    
    a {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        
    }
    ol, ul {
        list-style: none;
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    
    li {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;    

        &.is-disabled {
            .sf-nav-menu-item {
                cursor: default;
                opacity: 0.5;    
            }
        }
    }
    
    div {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    }
    
    .sf-nav.sf-nav-tabs li.is-active {
        background: #1c6094;
    }
    
    .sf-nav.sf-nav-tabs li.is-active:after {
        background: #0692eb;
    }
    
    // @media only screen and (min-width: 1024px) {
    .sf-nav.sf-nav-tabs li.is-active:before {
        position: absolute;
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #0692eb;
        z-index: 101;
        bottom: -8px;
        left: calc(50% - 5px);
    }
    // }
    
    // @media only screen and (min-width: 1024px) {
    .sf-nav.sf-nav-tabs li:after {
        display: block;
        content: "";
        height: 3px;
        width: 100%;
        position: absolute;
        background: #1d4d73;
        z-index: 101;
        left: 0;
        right: 0;
        top: calc(100% + 1px);
    }
    // }
    
    .sf-nav.sf-nav-tabs li a i {
        padding-right: 10px;
    }
    
    .sf-nav.sf-nav-tabs li a:hover {
        text-decoration: none;
    }

}

.sf-nav.sf-nav-tabs li.is-active .sf-nav-menu-item:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #0692eb;
    right: -12px;
    top: calc(50% - 4px);
}

//************************************** HEADER TOOL BAR end *****************************************

//************************************** MD-SWITCH begin *****************************************
md-switch .md-bar {
    top: 3px;
    height: 18px;
}

md-switch .md-thumb {
    height: 16px;
    width: 16px;
    top: 2px;
    left: 3px;
}

md-switch .md-label {
    font-size: 12px;
}

.custom-switch {
    md-switch {
        margin: 0;
    }
    md-switch.md-checked:not([disabled]) {
        .md-bar {
            background-color: #0692eb;
        }
        .md-thumb {
            background-color: white;
            left: 0px;
        }
        .md-label {
            color: $-status-color;
        }
    }
}

//************************************** MD-SWITCH end *******************************************


.tooltip {
    &.size-sm {
        font-size: 12px;
        height: 26px;
        padding: 5px
    }
    &.size-md {
        font-size: 16px;
        height: 30px;
        padding: 5px
    }
}


//************************************** FINGERPTTINING start *******************************************


#myProgress {
    width: 100%;
    background-color: lightslategray;
    height: 20px;
}

#myProgress2 {
    width: 100%;
    background-color: lightslategray;
    height: 5px;
}

#progress_bar_ob_det {
    width: 100%;
    background-color: lightslategray;
    height: 15px;
}

#progress_bar_all_ob_det {
    width: 100%;
    background-color: lightslategray;
    height: 15px;
}


#myBar {
    width: 1%;
    height: 20px;
    background-color: #00df00;
}

#myBar2 {
    width: 1%;
    height: 20px;
    background-color: #00df00;
}

#myBar3 {
    width: 1%;
    height: 5px;
    background-color: #00df00;
}

#my_bar_obj_det {
    width: 1%;
    height: 15px;
    background-color: #00df00;
}

#my_bar_all_obj_det {
    width: 1%;
    height: 15px;
    background-color: #00df00;
}

.padding-15 {
    padding: 15px;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.img-size {
    position: absolute;
    width: 98%;
    transform: scale(1);
    transform-origin: 0 0;
}

.thumb-size {
    max-width: 640px;
    max-height: 480px;
    width: 100%;
}

.thumb-size-border {
    max-width: 640px;
    max-height: 480px;
    width: 100%;
    border: 2px solid red;
}

//************************************** FINGERPTTINING end *******************************************


.color-text-red{
    color: red;
}

.color-text-green{
    color: green;
}


.tooltip.customClass2 .tooltip-inner {
    color: #333;
    background-color: transparent;
    background: transparent;
    width: 500px;
     
    opacity: 1;
    filter: alpha(opacity=1000);
    text-align: left;
    width: 500px;

}
/* Hide arrow */
.tooltip.customClass2 .tooltip-arrow {
    display: none;
    width: 500px;
}

.tooltip.customClass2 {
    opacity: 1;
    position: absolute;
    width: 500px;
}

.tooltip{
    &.customClass2{
        z-index: 100;
        position: absolute!important;
        width: 500px;
    }
}

.webcam-position, .webcam-position-mobile {
    position: fixed;
    background: #2c405a;
    padding: 10px;
    box-shadow: 5px 5px 15px 5px #000000;    
    text-align: center;
    
    &.top {
        top: 100px;
    }

    .button-container {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        padding-bottom: 10px;
    }

}

.webcam-position  {
    width: 320px;
    height: 285px;

    .webcam .webcam-live  {
        width: 300px;
        height: 225px;    
    }

    .canvas {
        position: absolute; 
        left: calc(50% - 150px);        
        z-index: 10;
        width: 300px;
        height: 225px;    
    }

    .area {
        position: absolute;
        width: 300px;
        height: 225px;
        left: calc(50% - 150px);
        z-index: 9;        
    }

    &.center {
        left: calc(50% - 150px);
    }

}

.webcam-position-mobile  {
    width: 240px;
    height: 240px;

    .webcam .webcam-live  {
        width: 220px;
        height: 165px;
    }

    .canvas {
        position: absolute; 
        left: calc(50% - 110px);        
        z-index: 10;
        width: 220px;
        height: 165px;
    }

    .area {
        position: absolute;
        width: 220px;
        height: 165px;
        left: calc(50% - 110px);
        z-index: 9;        
    }

    &.center {
        left: calc(50% - 120px);
    }

}

//========================================== DROPDOWN-MENU begin =========================================================

.dropdown-submenu {
    position:relative;
}
.dropdown-submenu>.dropdown-menu {
    top:0;
    // left:-10rem; /* 10rem is the min-width of dropdown-menu */
    left:calc(100% - 5px); /* 10rem is the min-width of dropdown-menu */
    margin-top:-6px;
}

/* rotate caret on hover */
.dropdown-menu > li > a:hover:after {
    text-decoration: underline;
    transform: rotate(-90deg);
} 


//========================================== DROPDOWN-MENU end ===========================================================


//========================================== ACCORDION begin ===========================================================
.accordion-container {
    border: 1px solid rgba(255,255,255,.1);
    @extend .myLabel;
    @extend .myLabel.color-white;

    .header {
        background-color: #1c6094;
        height: 30px;
        padding: 5px;
    }

    .body {
        @extend .myLabel.no-bold;
        // padding: 5px;
    }
}
//========================================== ACCORDION end =============================================================

//========================================== DATEPICKER begin ===========================================================
.md-datepicker-calendar-pane{
    z-index: 1100;
    border-color: #eaeaea;
}
.md-calendar tbody {
    background: #eaeaea;
}
//========================================== DATEPICKER end =============================================================


//========================================== AIBUILDER TOOL begin ===========================================================

.aibuilder-color {
    height: revert!important;
}

.header-aibuilder {
    top: 50px;
    padding:10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-top: 10px!important;
}

.aibuilder-container {
    flex: 0 0 100px; 
    z-index: 11
}

.aibuilder-item {
    z-index: 10;
    max-height: 500px;
    width:100%; 
    overflow-y: auto;
}

.aibuider-item-button{
    width: 250px; 
    margin: 0 auto;
}

.aibuilder-single-item {
    padding: 2px;
    width: 90%; 
    display: flex;
}

.aibuilder-lock {
    height: 25px; 
    width: 25px; 
    // padding-left: 10px;
}

.aibuilder-class-color {
    height: 25px; 
    width: 25px;
    padding-left: 20px;
    padding-top: 3px;
}

.aifilter-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.aifilter-form {
    margin-bottom: 0px;
}

.aifilter-tag-selected {
    display: inline-block;
    border-radius: 5px;
    border: 1px;
    height: 15px;
    width: 15px;
    vertical-align: middle;
}

.aifilter-class-color {
    height: 25px; 
    width: 25px;
    padding-left: 20px;
}

.aifilter-item {
    display: flex; 
    padding-left: 25px; 
    align-items: center;
    height: 30px;
}

//========================================== AIBUILDER TOOL  end ===========================================================

